export default function CollStatement(){

    return(

        <section className="termsservice  gt__bg-image categorysec categorysec2 categorysec3" >
        <div className="container">
            <div className="ctextinfo">
                <h1>Collection Statement</h1>
                <p>

                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque felis imperdiet proin fermentum leo vel orci porta non. A iaculis at erat pellentesque adipiscing commodo elit at imperdiet. Posuere urna nec tincidunt praesent semper feugiat. Consectetur lorem donec massa sapien faucibus et molestie ac feugiat. Est ante in nibh mauris cursus mattis molestie a iaculis. Volutpat sed cras ornare arcu dui vivamus arcu felis. Tellus in hac habitasse platea dictumst vestibulum rhoncus est. Donec massa sapien faucibus et molestie ac feugiat. Volutpat lacus laoreet non curabitur.

                </p>
                <p>

                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque felis imperdiet proin fermentum leo vel orci porta non. A iaculis at erat pellentesque adipiscing commodo elit at imperdiet. Posuere urna nec tincidunt praesent semper feugiat. Consectetur lorem donec massa sapien faucibus et molestie ac feugiat. Est ante in nibh mauris cursus mattis molestie a iaculis.

                </p>
                <p>
                    Volutpat sed cras ornare arcu dui vivamus arcu felis. Tellus in hac habitasse platea dictumst vestibulum rhoncus est. Donec massa sapien faucibus et molestie ac feugiat. Volutpat lacus laoreet non curabitur.
                </p>
            </div>
        </div>
    </section>

    )
}