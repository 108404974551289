import React, { useEffect, useState } from "react";
import Slider from 'react-slick'
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useParams } from "react-router-dom";
import ArrowUp from "../images/arrow-up.svg"
import Share from './share'
import { Cookies } from 'react-cookie'
import ReadMoreText from 'read-more-less-react';
import { InstagramShareButton } from 'react-share';
import 'read-more-less-react/dist/index.css'
// import localStorage from "react-secure-storage";
import ExpiredStorage from "expired-storage";



export default function Events() {


    const [refreshData, setRefreshData] = useState(false);

    // Page position scroll code start
    const handleScroll = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    // Page position scroll code end

    const [int, setInt] = useState('Interested ?');
    // let UserId = localStorage.getItem('id');
    const cookies = new Cookies();
    let UserId = cookies.get('id');
    // home to details page start
    const { id } = useParams();


    // home to details page end
    const headers = {
        'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',
    }

    // Image url path

    const baseURL = "https://backend.theticketcompany.com.au/image/";
    const baseURLs = "https://backend.theticketcompany.com.au/image/eventVideo";
    const sponserimgURL = "https://backend.theticketcompany.com.au/image/sponser/";
    const OrganizerimgURL = "https://backend.theticketcompany.com.au/image/organizerLogo/"



    const [data, setData] = useState();
    const [sponser, setsponser] = useState();
    const [datas, setDatas] = useState();


    const getAllData = () => {
        axios.get(`https://backend.theticketcompany.com.au/api/events?eventId=${id}&userId=${UserId}`, {
            headers: headers
        }).then((response) => {

            // console.log(response.data.response_data);
            // console.log(response.data.event_artist_image);
            localStorage.setItem("event_type", response.data.response_data.event_type);
            setData(response.data.response_data);


            if (response.data.response_data.likes == 'true') {

                setInt('Interested')
            }
            else {
                setInt('Interested ?')
            }
            localStorage.setItem("place_id", response.data.response_data.place_id);
            localStorage.setItem("event_name", response.data.response_data.event_name);
            localStorage.setItem("place_name", response.data.response_data.place_name);
            localStorage.setItem("events_id", id);
            localStorage.setItem("event_date", response.data.response_data.event_date);
            localStorage.setItem("event_time", response.data.response_data.event_time);
            setRefreshData(false)


        }).catch((error) => {
            console.log(error);
        });
    };

    const getsponser = () => {
        axios.get(`https://backend.theticketcompany.com.au/api/eventsponser?event_id=${id}`, { headers: headers }).then((response) => {

            // console.log(response.data.response_data);
            setsponser(response.data.response_data);

        }).catch((error) => {
            // console.log(error);
        });
    };

    const getbanner = () => {
        axios.get('https://backend.theticketcompany.com.au/api/event', {
            headers: headers
        }).then((response) => {

            // console.log(response.data.response_data);
            setDatas(response.data.response_data);


        }).catch((error) => {
            // console.log(error);
        });
    };


    useEffect(() => {
        getAllData();
    }, [refreshData])

    useEffect(() => {
        getAllData();
        getsponser();
        getbanner();
    }, [id]);



    // Count Down Code Start
    const [state, setState] = useState({
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
    });

    useEffect(() => {
        const event_dates = localStorage.getItem('event_date');
        const event_times = localStorage.getItem('event_time');
        const newdateTime = event_dates + ' ' + event_times;

        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const countdownDate = new Date(newdateTime).getTime();

            if (countdownDate > currentTime) {
                const distanceToDate = countdownDate - currentTime;
                let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
                let hours = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

                const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

                days = `${days}`;
                if (numbersToAddZeroTo.includes(hours)) {
                    hours = `0${hours}`;
                } else if (numbersToAddZeroTo.includes(minutes)) {
                    minutes = `0${minutes}`;
                } else if (numbersToAddZeroTo.includes(seconds)) {
                    seconds = `0${seconds}`;
                }

                setState({ days, hours, minutes, seconds });
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [localStorage.getItem('event_date'), localStorage.getItem('event_time')]); // Add event_date and event_time as dependencies


    // Count Down Code end

    // Like Event Code Start
    const [likedata, setLike] = useState()

    function likeEvent(e) {
        const getId = e.target.id;
        const likedata = data.likes
        // console.log(likedata);


        if (!getId) {
            // console.log("Please Login First");
            window.location = "/login";
        }
        else {
            setRefreshData(true)
            if (likedata == 'false') {
                const body = new FormData();

                body.append("user_id", getId);
                body.append("event_id", id);


                const headers = {
                    'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


                }
                axios.post('https://backend.theticketcompany.com.au/api/likes', body, {
                    headers: headers
                })
                    .then((res) => {
                        if (getId) {
                            setLike(res.data.response_data);

                        }
                        else {
                            window.location = "/login";
                        }
                    }).catch((error) => {
                        // console.log(error)
                    });



            } else {

                getAllData();
                const body = new FormData();

                body.append("user_id", getId);
                body.append("event_id", id);


                const headers = {
                    'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


                }
                axios.post('https://backend.theticketcompany.com.au/api/dislikes', body, {
                    headers: headers
                })
                    .then((res) => {
                        if (getId) {
                            setLike(res.data.response_data);

                        }
                        else {
                            window.location = "/login";
                        }
                    }).catch((error) => {
                        // console.log(error)
                    });



            }

        }

    }
    var sponserSettings = {
        dots: true,
        infinite: true,
        speed: 350,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 350,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,


    };



    return (
        <>
            {data && <main>
                <Slider {...settings}>

                    <div className="slide slide-height" >

                        <img className="slider-image" src={baseURL + data.event_banner} />
                    </div>
                    {data.event_video == "eventVideo/" ||
                        <div className="slide slide-height" >
                            <video src={baseURL + data.event_video} type="video/mp4" width="200" muted autoPlay={"autoplay"} preLoad="auto" loop />
                        </div>
                    }
                </Slider>

                {/* hero section end*/}



                <section className="eventinfo">
                    <div className="container">
                        <div key={data.id}>
                            <div className="eventtitle">
                                <h2 className="ctitle" style={{ "width": "80%", "lineHeight": "30px" }}>{data.event_name}</h2>
                                <p>{data.categories} | {data.event_hours}</p>
                                {data.place_name && data.place_name == "Plenary  Melbourne Convention and Exhibition Centre - P" && <NavLink onClick={handleScroll} to="/plenary" className="cbtn cbtns" >Buy Tickets</NavLink> ||
                                    data.place_type == "Party Plot" &&
                                    <NavLink onClick={handleScroll} to="/bookingstep/select-ticket" className="cbtn cbtns">Buy Tickets</NavLink> ||
                                    <NavLink onClick={handleScroll} to="/seat-booking" className="cbtn cbtns" >Buy Tickets</NavLink>
                                }

                            </div>
                            <ul>
                                <li>
                                    <div className="icon icon1" />
                                    <div className="desc">
                                        {data.event_date == data.event_end_date &&
                                            <h3>{data.event_date} At {data.event_time}</h3> ||
                                            <h3>{data.event_date} - {data.event_end_date} At {data.event_time}</h3>}
                                        {/* <p>{data.event_booking_date} To {data.event_booking_end_date} <br />
                                        </p> */}
                                    </div>
                                </li>
                                <li>
                                    <div className="icon icon2" />
                                    <div className="desc">
                                        <h3>Location/Venue</h3>
                                        <p>{data.place_name} </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon icon3" />
                                    <div className="desc">
                                        <h3>Tickets</h3>
                                        <p className="price">${data.lowestPrice} - ${data.highestPrice}</p>
                                    </div>
                                </li>

                                <li>
                                    <div>
                                        <div className='countdown-wrapper'>
                                            <div className='time-section'>
                                                <div className='time'>{state.days || '0'}</div>
                                                <small className="time-text">Days</small>
                                            </div>
                                            <div className='time-section'>
                                                <div className='time'>:</div>
                                            </div>
                                            <div className='time-section'>
                                                <div className='time'>{state.hours || '00'}</div>
                                                <small className="time-text">Hours</small>
                                            </div>
                                            <div className='time-section'>
                                                <div className='time'>:</div>
                                            </div>
                                            <div className='time-section'>
                                                <div className='time'>{state.minutes || '00'}</div>
                                                <small className="time-text">Minutes</small>
                                            </div>
                                            <div className='time-section'>
                                                <div className='time'>:</div>
                                            </div>
                                            <div className='time-section'>
                                                <div className='time'>{state.seconds || '00'}</div>
                                                <small className="time-text">Seconds</small>
                                            </div>
                                        </div>

                                    </div>
                                </li>

                            </ul>

                        </div>


                    </div>
                </section>
                <section className="eventabout">
                    <div className="container">
                        <div className="eventimgbox">
                            <img className="eventimgbox1" src={data.event_artist_image} alt="" />
                        </div>

                        <div className="eventtext ctectinfo">
                            {/* <h3 className="ctitle">ABOUT</h3> */}
                            <span style={{ fontWeight: "bold" }}>ABOUT :</span>
                            <div className="extra-content cpoint" dangerouslySetInnerHTML={{ __html: data.event_about }} />

                            <br />
                            <div>
                                <span style={{ fontWeight: "bold" }}>Terms & condition :</span>
                                <div className="cpoint" dangerouslySetInnerHTML={{ __html: data.event_terms }} />
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>Event Disclaimer :</span>

                                <div className="cpoint" dangerouslySetInnerHTML={{ __html: data.event_disclaimer }} />
                            </div>
                            <div className="notetext">
                                <span style={{ fontWeight: "bold" }}>Note :</span>
                                {/* <ul className="cpoint">
                                    <li>{data.event_note}</li>
                                </ul> */}
                                <div className="cpoint" dangerouslySetInnerHTML={{ __html: data.event_note }} />
                            </div>
                            <br />


                            <div className="interested">
                                <h5>Click on Interested to stay updated about this event.</h5>

                                <div className="like" >{data.likeCounts}</div>

                                <p>People have shown interest recently</p>
                                {int === "Interested" && <a className="cbtn cbtn2" id={UserId} onClick={(e) => (likeEvent(e))} >{int}</a>

                                    || <a className="cbtn cbtn1" id={UserId} onClick={(e) => (likeEvent(e))} >{int}</a>
                                }
                            </div>
                            <br /><br />
                            <div className="shareevent d-flex">
                                <h5>Share This Event :- </h5>
                                <div className="gt__social-img gt_social_icons d-flex d-flex-wrap align-items-center">
                                    <div className="d-flex align-items-center">
                                        <Share />



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="organizers">
                            <h2>
                                Organised By -
                            </h2>
                            <Slider {...sponserSettings}>

                                <div className="eventimgbox">

                                    <h3 className="organizername">
                                        {data.organizer_name}
                                    </h3>

                                    <img style={{ width: "33%" }} className="eventimgbox1" src={data.organizer_logo} alt="" />
                                </div>
                            </ Slider>
                        </div>
                    </div>
                </section>
                {/* <section className="sponsorsection">
                    <Slider {...sponserSettings}>
                        {sponser ? sponser.map((sponser, index) => {
                            return (<div className="container" key={index}>

                                <div className="sponsorbox">
                                    <div className="imgbox">
                                        <img src={sponserimgURL + sponser.sponser_logo} alt="" />
                                    </div>
                                    <div className="desc">
                                        <h3>{sponser.sponser_name}</h3>
                                        <p>{sponser.sponser_information}</p>
                                        <p>We are proud to say that, This Event is sponsored by Domino's Pizza</p>
                                    </div>

                                </div>

                            </div>)
                        }) : <> </>}
                    </Slider>
                </section> */}
                {/* Top button start*/}
                <div className="gt-top-button">
                    <a id="toTop" onClick={handleScroll}><img src={ArrowUp} alt="gotop" width={24} height={24} /></a>
                </div>
                {/* Top button end*/}


            </main> || <h3><div className="loader"></div></h3>}
        </>
    )
}