// ** create-user.component.js ** //
import React, { Component, useEffect, useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import Leftarrow from '../images/leftarrow-white.svg';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie'
import backButton from 'browser-back-button';
import { ErrorMessage } from '@hookform/error-message';
import secureLocalStorage from "react-secure-storage";
import ExpiredStorage from "expired-storage";

const val = Math.floor(1000 + Math.random() * 9000);
const Oid = `${val}`;

export default function UserDetails() {



    const handleScroll = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    const [refreshData, setRefreshData] = useState(false);
    const expiredStorage = new ExpiredStorage();
    const item = expiredStorage.getItem("test");
    useEffect(() => {
        const item = expiredStorage.getItem("test");
    }, [refreshData])

    const [session, setsession] = useState(true);

    const closesession = () => {
        setsession(false);
        navigate("/")
    }


    // back history button
    backButton.on(() => {
        setOpen(true)
    });
    window.onpopstate = e => {
        setOpen(true)
    }

    const [url, seturl] = useState(false);

    const onchangehelp = () => {
        seturl(window.location.pathname)
    }

    // window.addEventListener("popstate",onchangehelp())
    // window.removeEventListener("popstate",onchangehelp())

    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const [submit, setSubmit] = useState("Book Ticket");

    const onLoadModal = () => setLoad(true);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    // let AdCount = secureLocalStorage.getItem('AdCount');


    // let UserId = secureLocalStorage.getItem('id');
    const cookies = new Cookies();
    let UserId = cookies.get('id');

    let place_id = localStorage.getItem('place_id');


    // let seatids = secureLocalStorage.getItem('seatid');

    // let SeatNumber = secureLocalStorage.getItem('seatname');
     let SeatNumber1 = localStorage.getItem('seatname1');
    // let amt = secureLocalStorage.getItem('amount');

    let AdCount = cookies.get('AdCount');
    let seatids = cookies.get('seatid');
    let SeatNumber = cookies.get('seatname');
   // let SeatNumber1 = cookies.get('seatname1');
    let amt = cookies.get('amount');
    const event_id = localStorage.getItem('events_id');
    let event_name = localStorage.getItem('event_name');
    let event_date = localStorage.getItem('event_date');
    let place_name = localStorage.getItem('place_name');
    // let ticketBooking_id = secureLocalStorage.getItem('ticketBooking_id');
    let ticketBooking_id = cookies.get('ticketBooking_id');


    const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "PDF"];
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const nameRegExp = /[A-Za-z]/
    const nameRegEx = /[A-Za-z]{3}/

    useEffect(() => {
        if (!UserId) {
            setSubmit("Login to Proceed")
        }
        else {
            // console.log("hi");
        }

    }, [])



    const formOptions = { mode: 'onChange' };
    const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

    // For cancel transaction code
    function cancel() {

        // let ticketBooking_id = secureLocalStorage.getItem('ticketBooking_id');
        let ticketBooking_id = cookies.get('ticketBooking_id');

        const body = new FormData();

        body.append("tid", ticketBooking_id);

        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',

        }

        axios.post('https://backend.theticketcompany.com.au/api/cancellation', body, {
            headers: headers
        })
            .then((res) => {
                if (res.data.status == true) {
                    setData(res.data.response_data);
                    // console.log(res.data.status);
                    window.history.replaceState(null, null, "/");
                    navigate(`/event-detail/${event_name.replace(/[^A-Z0-9]+/ig, '-')}/${event_id}`);
                }
                else {
                    // console.log(res.data.status)
                }

            }).catch((error) => {
                console.log(error)
            });



    }


    const [data, setData] = useState()
    const navigate = useNavigate();
    function onSubmit(val) {
        setRefreshData(true)
        onLoadModal();
        setSubmit("Prosessing...")


        let ticketBooking_id = localStorage.getItem('ticketBooking_id');
        // let ticketBooking_id = cookies.get('ticketBooking_id');
        // console.log(ticketBooking_id);
        localStorage.setItem("oid", Oid);

        const body = new FormData();


        body.append("first_name", val.first_name);
        body.append("last_name", val.last_name);
        body.append("mobile_no", val.mobile_no);
        body.append("user_id", UserId);
        body.append("email_id", val.email_id);
        body.append("place_id", place_id);
        body.append("dome_price_id", seatids);
        body.append("event_id", event_id);
        body.append("ticketBooking_id", ticketBooking_id);
        body.append("in_progress", "0");
        body.append("dome_list_id", SeatNumber1);



        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


        }

            axios.post('https://backend.theticketcompany.com.au/api/booking-ticket', body, {
                headers: headers
            })
                .then((res) => {
                    if (res.data.status == true) {
                        setData(res.data.response_data);
                        localStorage.setItem("pid", res.data.pid);

                        cookies.set("pid", res.data.pid);

                        // console.log(res.data.pid);


                        navigate('/checkout');
                    }
                    else {
                        // console.log(res.data.status)
                    }

                }).catch((error) => {
                    console.log(error)
                });



    }
    const navigates = useNavigate();
    return (
        <>
            {item && <>
                <div>
                    <Modal open={load} showCloseIcon={false} center>
                        <h3><div className="loader1"></div></h3>
                    </Modal>

                    <Modal open={open} showCloseIcon={false} center>
                        <h2>Cancel Transaction ? </h2>
                        <p>Your selected seats will be unblocked and may not be<br></br> available later if you cancel.</p>
                        <br></br>
                        <button onClick={cancel} className="cancel">Yes,cancel</button>
                        <button onClick={onCloseModal} type="submit" className="no">No</button>
                    </Modal>
                </div>
                {AdCount && <main>
                    <section className="gt__user-sec ticketsec ticketsec2">
                        <div className="ticketinfo">
                            <img onClick={onOpenModal} src={Leftarrow}></img>

                            <h2>{event_name}</h2>
                            <p className="venuedate venueinfo">{event_date}<span className="venueinfo">{place_name}</span></p>
                            <p className="venuedate venueinfo">Ticket Amount - ${amt}<span className="venueinfo">{AdCount} Seats</span><span className="venueinfo">{SeatNumber}</span></p>

                        </div>
                    </section>
                    {/* {UserForm && */}

                    <section className="gt__signup-sec gt__user-sec gt__bg-image gt__bg-image1">

                        <h1 className="txt-center">Attendee details</h1>
                        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                            {(() => {
                                const userdata = [];

                                for (let i = 0; i < AdCount; i++) {
                                    userdata.push(
                                        <>
                                            <div className="gt__signin-wrap gt__user-wrap">
                                                <div className="gt__form-block gt__form-block1">
                                                    <h4 className="gt__user-title gt__user-title1 txt-center"><span>Attendee ({i + 1})</span></h4>

                                                    <div className="gt__form-grp gt__uname d-flex d-flex-wrap justify-content-between align-items-center">
                                                        <div className="gt__fname-block">
                                                            <label htmlFor="fname">First Name</label>
                                                            <input type="text" name={'first_name[' + i + ']'}
                                                                {...register('first_name[' + i + ']',
                                                                    {
                                                                        required: "*First name is required.",

                                                                        minLength: {
                                                                            value: 3,
                                                                            message: "Please enter minimum 3 character."
                                                                        },
                                                                        pattern: {
                                                                            value: /^[a-zA-Z ]*$/,
                                                                            message: "Please enter character only."
                                                                        }

                                                                    },

                                                                )} />

                                                            <div className="invalid-feedback">
                                                                <ErrorMessage

                                                                    errors={errors}
                                                                    name={'first_name[' + i + ']'}
                                                                    render={({ message }) => <p>{message}</p>}
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="gt__fname-block">
                                                            <label htmlFor="lname">Last Name</label>
                                                            <input type="text" name={'last_name[' + i + ']'}
                                                                {...register('last_name[' + i + ']',
                                                                    {
                                                                        required: "*Last name is required.",
                                                                        // minLength: {
                                                                        //     value: 3,
                                                                        //     message: "Please enter minimum 3 character."
                                                                        // },
                                                                        pattern: {
                                                                            value: /^[a-zA-Z ]*$/,
                                                                            message: "Please enter character only."
                                                                        }
                                                                    },
                                                                )} />

                                                            <div className="invalid-feedback">
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name={'last_name[' + i + ']'}
                                                                    render={({ message }) => <p>{message}</p>}
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="gt__fname-block space1">
                                                            <label htmlFor="phone">Phone Number</label>
                                                            <input type="text" name={'mobile_no[' + i + ']'}
                                                                {...register('mobile_no[' + i + ']',
                                                                    {
                                                                        required: "*Phone number is required.",

                                                                        pattern: {
                                                                            value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
                                                                            message: "Please enter number only."
                                                                        }
                                                                    },
                                                                )} />

                                                            <div className="invalid-feedback">
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name={'mobile_no[' + i + ']'}
                                                                    render={({ message }) => <p>{message}</p>}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="gt__fname-block space1">
                                                            <label htmlFor="email">Email</label>
                                                            <input type="text" name={'email_id[' + i + ']'}
                                                                {...register('email_id[' + i + ']',
                                                                    {
                                                                        required: "*Email is required.",

                                                                        pattern: {
                                                                            value: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                                                                            message: "Please enter valid email only."
                                                                        }

                                                                    },

                                                                )} />

                                                            <div className="invalid-feedback">
                                                                <ErrorMessage

                                                                    errors={errors}
                                                                    name={'email_id[' + i + ']'}
                                                                    render={({ message }) => <p>{message}</p>}
                                                                />
                                                            </div>
                                                        </div>




                                                        <div className="gt__fname-block space1">
                                                            {/* <label htmlFor="dome_price_id">Dome Id</label> */}
                                                            <input type="hidden" name="dome_price_id" value={AdCount} {...register('dome_price_id')} />
                                                        </div>
                                                        <div className="gt__fname-block space1">
                                                            {/* <label htmlFor="user_id">User Id</label> */}
                                                            <input type="hidden" name="user_id" value={UserId}  {...register('user_id')} />
                                                        </div>
                                                        <div className="gt__fname-block space1">
                                                            {/* <label htmlFor="user_id">User Id</label> */}
                                                            <input type="hidden" name="user_id" value={UserId}  {...register('user_id')} />
                                                            <input type="hidden" name={'getid'}   {...register('getid')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><br /></>
                                    );
                                }

                                return userdata;
                            })()}

                            <div className="gt__form-grp gt__form-btn txt-center">
                                <button type="submit" onClick={handleScroll} className="gt__btn"  >{submit}</button>
                            </div>
                        </form>
                    </section>
                    {/* } */}
                </main> || <h3><div className="loader"></div></h3>}
            </> || <><Modal open={session} showCloseIcon={false} center>
                <p style={{ "margin-bottom": "59px" }}>Sorry, your session has expired</p>
                <button onClick={closesession} className="cancel">Start again</button>
            </Modal></>}
        </>
    )
}