import axios from 'axios';
import stayle from './Checkout.css';
import React, { Component, useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Navigate, useNavigate } from 'react-router-dom';
import Logo from '../images/The_ticket_company_logo.svg';
import Leftarrow from '../images/leftarrow-white.svg';
import backButton from 'browser-back-button';
import secureLocalStorage from "react-secure-storage";
import ExpiredStorage from "expired-storage";

const CryptoJS = require("crypto-js");
const { v4: uuidv4 } = require("uuid");
const apiKey = "akU7ieZVCT4KLKFQOplfNyUDZiUqgHi5";
const secret = "nmizeURLFSAjJZHMDSCHASFM4Z4foGNbd7g1V7Cybrr";
const times = new Date().getTime();

const val = Math.floor(1000 + Math.random() * 9000);
// console.log(val);
const Oid = `${val}`;


export default function Checkout() {

    // create random number

    let oid = localStorage.getItem("oid");


    // create random number end

    // Page position scroll code start
    const handleScroll = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    // Page position scroll code end

    //session time oout
    const [refreshData, setRefreshData] = useState(false);
    const expiredStorage = new ExpiredStorage();
    const item = expiredStorage.getItem("test");
    useEffect(() => {

        const item = expiredStorage.getItem("test");

    }, [refreshData])

    // session Code end


    // back history button
    backButton.on(() => {
        setback(true);
    });


    window.onpopstate = e => {
        setback(true);
    }

    // window.addEventListener("popstate",console.log(window.location.pathname))
    // window.removeEventListener("popstate", console.log(window.location.pathname))


    // Model Popup
    const [open, setOpen] = useState(false);
    const [back, setback] = useState(false);
    const [session, setsession] = useState(true);
    const closesession = () => {

        setsession(false);
        navigate("/")

    }

    const onOpenModal = () => setOpen(true);
    const onOpenModal2 = () => setback(true);

    const onCloseModal = () => {
        setOpen(false);
    }

    const onCloseModal1 = () => {
        setback(false);
    }

    const [load, setLoad] = useState(false);
    const onLoadModal = () => setLoad(true);

    const [applyopen, setapplyOpen] = useState(false);
    const [applymsg, setapplymsg] = useState("");

    const onOpenModal1 = () => applyopen(true);

    const onCloseModal2 = () => {
        setapplyOpen(false);
    }

    let AdCount = localStorage.getItem('AdCount');

    const cookies = new Cookies();
    let UserId = cookies.get('id');


    // let amt = secureLocalStorage.getItem('amount');
    let UserRoll = cookies.get('user_role');
    let amt
    if (UserRoll == 5) {
        amt = 0;
    }
    else{
        amt = cookies.get('amount');
    }
    let SeatNumber1 = localStorage.getItem('seatname1');
    let event_name = localStorage.getItem('event_name') || localStorage.getItem('combo_event_name');
    let event_date = localStorage.getItem('event_date') || localStorage.getItem('combo_event_date');
    let place_name = localStorage.getItem('place_name') || localStorage.getItem('combo_place_name');
    let pid = localStorage.getItem('pid');
    let event_id = localStorage.getItem('events_id');
    let SeatNumber = localStorage.getItem('seatname');
    let place_id = localStorage.getItem('place_id');



    // Start code Combo event_datils get into localstorage
    //  event_name =
    let combo_event_id = localStorage.getItem('combo_event_id');
    let event_type = localStorage.getItem('event_type') || localStorage.getItem('event_type');
    // console.log('event_type', event_type)


    // End code Combo event_datils get into localstorage

    let [inclusive_fix, Setinclusive_fix] = useState();

    if (UserRoll == 5) {
        inclusive_fix = 0;
    }


    // console.log(inclusive_fix);

    const [data, setData] = useState();
    const [apply, setApply] = useState("");
    const [voucheramt, setVoucheramt] = useState(0);
    const [vouchername, setVouchername] = useState("");
    const [total, setTotal] = useState(amt);
    // setTotal(parseFloat(total) + parseFloat(inclusive_fix));



    let tested;

if (UserRoll == 5) {
    tested = 0;
}
else{
    tested = parseFloat(total) + parseFloat(inclusive_fix);
}
    const onSearchHandler = (e) => {
        // console.log(e.target.value);
        const val = e.target.value
        setApply(val)


    }
    // const tests = total
    // console.log(tests);
    const [validp, setValidp] = useState();
    const [testmsg, settestmsg] = useState();


    const removeVoucher = () => {
        setVoucheramt(0)
        setTotal(amt)
        setapplymsg("")

    }

    function cancel() {
        expiredStorage.updateExpiration("test", 0);
        let ticketBooking_id = localStorage.getItem('ticketBooking_id');
        // console.log(ticketBooking_id);

        const body = new FormData();

        body.append("tid", ticketBooking_id);

        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',

        }

        axios.post('https://backend.theticketcompany.com.au/api/cancellation', body, {
            headers: headers
        })
            .then((res) => {
                if (res.data.status == true) {
                    setData(res.data.response_data);
                    // console.log(res.data.status);
                    window.history.replaceState(null, null, "/");
                    if (event_type == "Single") {

                        navigate(`/event-detail/${event_name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()}/${event_id}`);
                    }else
                    {
                        navigate(`/combo-event-detail/${event_name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()}/${combo_event_id}`);
                    }
                }
                else {
                    // console.log(res.data.status)
                }

            }).catch((error) => {
                // console.log(error)
            });



    }
    const onApplyClick = (e) => {

        const val = apply

        setValidp(data.find(seat => seat.voucher_code === val))
        // console.log(validp)
        if (validp != " ") {
            const discount_type = data.find(seat => seat.voucher_code === val).discount_type;
            const discount = data.find(seat => seat.voucher_code === val).discount;
            const voucher_code = data.find(seat => seat.voucher_code === val).voucher_code;
            // console.log(voucher_code);

            // console.log(discount)
            if (discount_type == "Percentage") {
                const discountedAmt = Math.ceil(amt * discount / 100);
                const final = amt - discountedAmt
                setTotal(final);
                setVoucheramt(discountedAmt)
                setapplymsg("Voucher applied sucessfully")
                setVouchername(voucher_code)
            }
            else {
                const discountedAmt = amt - discount;
                setVoucheramt(discount)
                if (discountedAmt < 0) {
                    setTotal(0)
                    setapplymsg("Voucher applied sucessfully")
                    setVouchername(voucher_code)
                }
                else {
                    setTotal(discountedAmt)
                    setapplymsg("Voucher applied sucessfully")
                    setVouchername(voucher_code)
                }

            }
        }
        else {
            settestmsg("Not valid");
            // console.log("Not valid");
        }




    }

    function applypromo(e) {
        const val = e.target.id;
        const discount_type = data[val].discount_type;
        const discount = data[val].discount;
        const voucher_code = data[val].voucher_code;
        // console.log(discount)
        if (discount_type == "Percentage") {
            const discountedAmt = Math.ceil(amt * discount / 100);
            const final = amt - discountedAmt
            setTotal(final);
            setVoucheramt(discountedAmt);
            setapplymsg("Voucher applied sucessfully");
            setVouchername(voucher_code);
        }
        else {
            const discountedAmt = amt - discount;
            setVoucheramt(discount)
            if (discountedAmt < 0) {
                setTotal(0)
                setapplymsg("Voucher applied sucessfully")
                setVouchername(voucher_code)
            }
            else {
                setTotal(discountedAmt)
                setapplymsg("Voucher applied sucessfully")
                setVouchername(voucher_code)
            }

        }

        onCloseModal();

    }
    const [CurrentDate, Setdate] = useState();

    // console.log('event_type', event_type)

    function commission() {
        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',

        }

        if (event_type == "Combo") {
            axios.get(`https://backend.theticketcompany.com.au/api/comboeventsdetails?comboEventId=${combo_event_id}&userId=${UserId}`, {
                headers: headers
            }).then((response) => {

                // console.log(response.data.response_data.current_time);
                Setdate(response.data.response_data.current_time);
                let response_data = response.data.response_data;

                if (response_data.commission_amount_type == "Inclusive" && response_data.commission_type == "Fixed") {

                    Setinclusive_fix(response_data.commission_value);
                    // console.log(response_data.commission_value);
                }
                else if (response_data.commission_amount_type == "Inclusive" && response_data.commission_type == "Percentage") {
                    const commissionAmt = Math.ceil(amt * response_data.commission_value / 100);
                    Setinclusive_fix(commissionAmt);
                    // console.log(commissionAmt);
                }
                else if (response_data.commission_amount_type == "Exclusive" && response_data.commission_type == "Fixed") {
                    Setinclusive_fix(response_data.commission_value);
                    // console.log(response_data.commission_value);

                }
                else if (response_data.commission_amount_type == "Exclusive" && response_data.commission_type == "Percentage") {
                    const commissionAmt = Math.ceil(amt * response_data.commission_value / 100);
                    Setinclusive_fix(commissionAmt);
                    // console.log(commissionAmt);
                }

            }).catch((error) => {
                // console.log(error);
            });
        }
        else {
            axios.get(`https://backend.theticketcompany.com.au/api/events?eventId=${event_id}&userId=${UserId}`, {
                headers: headers
            }).then((response) => {

                // console.log(response.data.response_data.current_time);
                Setdate(response.data.response_data.current_time);
                let response_data = response.data.response_data;

                if (response_data.commission_amount_type == "Inclusive" && response_data.commission_type == "Fixed") {

                    Setinclusive_fix(response_data.commission_value);
                    // console.log(response_data.commission_value);
                }
                else if (response_data.commission_amount_type == "Inclusive" && response_data.commission_type == "Percentage") {
                    const commissionAmt = Math.ceil(amt * response_data.commission_value / 100);
                    Setinclusive_fix(commissionAmt);
                    // console.log(commissionAmt);
                }
                else if (response_data.commission_amount_type == "Exclusive" && response_data.commission_type == "Fixed") {
                    Setinclusive_fix(response_data.commission_value);
                    // console.log(response_data.commission_value);

                }
                else if (response_data.commission_amount_type == "Exclusive" && response_data.commission_type == "Percentage") {
                    const commissionAmt = Math.ceil(amt * response_data.commission_value / 100);
                    Setinclusive_fix(commissionAmt);
                    // console.log(commissionAmt);
                }

            }).catch((error) => {
                // console.log(error);
            });
        }


    }


    // console.log(inclusive_fix);
    function getvoucher() {

        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',

        }

        axios.get(`https://backend.theticketcompany.com.au/api/getvoucher?event_id=${event_id}&dome_list_id=${SeatNumber1}`, {
            headers: headers
        }).then((response) => {

            // console.log(response.data.response_data);
            if (response.data.response_data == null) {
                setData(" ")
            }
            else { setData(response.data.response_data) }
        }).catch((error) => {
            // console.log(error);
        });


    }

    useEffect(() => {
        getvoucher();
        commission();
    }, [])
    const navigate = useNavigate();




    function proceed() {
        setRefreshData(true)

        if (tested == 0) {
            navigate('/responseSuccessURL')


        }

    }
    // console.log(data);



    // Payment getway start

    // set data

    const Senddata = () => {
        // secureLocalStorage.setItem("chekout_id", response.checkout.checkoutId);
        // window.location.href = response.checkout.redirectionUrl;
        localStorage.setItem("gross_amount", amt);
        localStorage.setItem("voucher_id", 1);
        localStorage.setItem("subtotal_amount", tested);
        localStorage.setItem("tax_amount", 0);
        localStorage.setItem("net_amount", tested);
        localStorage.setItem("descount_amount", voucheramt);
        if (event_type == "Combo") {

            localStorage.setItem("is_combo", 1);
            // console.log('yes')
        }
        else{

            localStorage.setItem("is_combo", 0);
            // console.log('no')

        }




    }
    // set data end

    const CryptoJS = require("crypto-js");

    let newdate = CurrentDate;

    let sharedSecret = "mDQaFg57tq";
    let separator = "|";
    let storeId = "3650650452";
    //$timezone= "Europe/London";
    let timezone = "Australia/Sydney";
    let txntype = "sale";
    let checkoutoption = "combinedpage";
    let mobileMode = true;

    const stringToHash = tested + separator + checkoutoption + separator + "036" + separator + "HMACSHA256" + separator + mobileMode + separator + oid + separator + "https://backend.theticketcompany.com.au/api/redirect" + separator + "https://backend.theticketcompany.com.au/api/redirect" + separator + storeId + separator + timezone + separator + "https://backend.theticketcompany.com.au/api/callback" + separator + newdate + separator + txntype;
    // console.log(stringToHash);

    var hashHMACSHA256 = CryptoJS.HmacSHA256(stringToHash, sharedSecret);
    var extendedhash = CryptoJS.enc.Base64.stringify(hashHMACSHA256);
    // console.log(extendedhash);
    // Payment getway end



    return (
        <>
            {item && <>
                <section className="gt__user-sec ticketsec ticketsec2">
                    <div className="ticketinfo">
                        <img onClick={onOpenModal2} src={Leftarrow}></img>

                        <h2>{event_name}</h2>
                        <p className="venuedate venueinfo">{event_date}<span className="venueinfo">{place_name}</span></p>
                        <p className="venuedate venueinfo">Ticket Amount-{amt}<span className="venueinfo">{AdCount} Seats</span><span className="venueinfo">{SeatNumber}</span></p>

                    </div>
                </section>
                <Modal open={load} showCloseIcon={false} center>
                    <h3><div className="loader1"></div></h3>
                </Modal>


                <Modal open={back} showCloseIcon={false} center>
                    <h2>Cancel Transaction ? </h2>
                    <p>Your selected seats will be unblocked and may not be<br></br> available later if you cancel.</p>
                    <br></br>
                    <button onClick={cancel} className="cancel">Yes,cancel</button>
                    <button onClick={onCloseModal1} type="submit" className="no">No</button>
                </Modal>

                {/* <Modal open={open} onClose={() => setOpen(false)} center>
                    <h3 className='phead' style={{ "margin-top": "35px" }}>Voucher Codes & Coupons</h3>
                    {data ? data.map((voucher, i) => {
                        return (<>
                            <div className="promocode">
                                <div>
                                    <p>{voucher.voucher_code} </p>
                                    <button id={i} onClick={(e) => (applypromo(e))} type="submit" className="apply">Apply</button>
                                </div>
                            </div>
                        </>)

                    }) : <h3>No voucher available</h3>}

                    <br></br>


                </Modal> */}

                <div className="categorysec3">
                    {/* ==========Banner-Section========== */}
                    <section className="details-banner hero-area bg_img seat-plan-banner" data-background="assets/images/banner/banner04.jpg">
                        <div className="gt__header-left">
                            <img style={{ "marginLeft": "585px", "marginBottom": "-68px", "marginTop": "28px", "width": "10%" }} src={Logo} alt="The_ticket_company" />

                        </div>
                    </section>

                    {/* ==========Movie-Section========== */}
                    <div className="movie-facility padding-bottom padding-top">
                        <div className="container1">
                            <div className="row">
                                <div className="col-lg-8">

                                    <div className="checkout-widget checkout-contact">
                                        <h5 className="title">Voucher Code </h5>
                                        <form className="checkout-contact-form">
                                            <div className="form-group">
                                                {!applymsg ? <><input className="inputs" type="text" value={apply} onChange={onSearchHandler} name='applycode' placeholder="Enter a Voucher code" />
                                                    <span className="custom-button back-button" onClick={onApplyClick}>Apply</span></> :
                                                    <>
                                                        <p>{applymsg}</p>
                                                        <p>{vouchername}</p>
                                                        <span className="custom-button back-button" onClick={removeVoucher}>Remove</span>
                                                    </>
                                                }
                                            </div>
                                            {/* <div className="form-group">

                                                <span onClick={onOpenModal} style={{ "textDecorationLine": "revert", "marginLeft": "60px", "color": "white" }}>View all voucher codes </span>

                                            </div> */}
                                        </form>
                                    </div>

                                </div>
                                <div className="col-lg-4">
                                    <div className="booking-summery bg-one">
                                        <h4 className="title">booking summary</h4>
                                        <ul>
                                            <li>
                                                <h6 className="subtitle">{event_name}</h6>
                                                <span className="info">{ }</span>
                                            </li>
                                            <li>
                                                <h6 className="subtitle subtitle1"><span>{place_name}</span><span>{AdCount}</span></h6>
                                                <div className="info"><span>{event_date}</span> <span>Tickets </span></div>
                                            </li>
                                            <li>
                                                <h6 className="subtitle mb-0"><span>Tickets Price</span><span>$ {amt}</span></h6>


                                            </li>


                                        </ul>

                                       <ul>
                                            <li>
                                            { event_type == "Single" && <span className="info"><span>Voucher Amount</span><span>- $ {voucheramt}</span></span> }

                                                <span className="info"><span>Booking & Handling Fees</span><span>+ $ {inclusive_fix}</span></span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="proceed-area  text-center1">
                                        <h6 className="subtitle subtitle1"><span>Amount Payable</span><span>$ {tested}</span></h6>
                                    </div>


                                    {tested == "0" && <div className="proceed-area  text-center1">
                                        <button onClick={() => { proceed(); Senddata(); }} className="custom-button back-button">Proceed</button >
                                    </div> ||
                                        <form method="post" action="https://www4.ipg-online.com/connect/gateway/processing">

                                            {/* <legend>IPG Connect Request Details</legend> */}
                                            <p>
                                                <label htmlFor="oid" hidden>oid:</label>
                                                <input type="hidden" name="oid" defaultValue={oid} readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="storename" hidden>Store ID:</label>
                                                <input type="hidden" name="storename" defaultValue={3650650452} readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="timezone" hidden>Timezone:</label>
                                                <input type="hidden" name="timezone" defaultValue="Australia/Sydney" readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="chargetotal" hidden>Transaction Type:</label>
                                                <input type="hidden" name="txntype" defaultValue="sale" readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="chargetotal" hidden>Transaction Amount:</label>
                                                <input type="hidden" name="chargetotal" defaultValue={tested} readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="currency" hidden>Currency (see ISO4217):</label>
                                                <input type="hidden" name="currency" defaultValue="036" readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="txndatetime" hidden>Transaction DateTime:</label>
                                                <input type="hidden" name="txndatetime" defaultValue={newdate} />
                                            </p>
                                            <p>
                                                <label htmlFor="hashExtended" hidden>Hash Extended:</label>
                                                <input type="hidden" name="hashExtended" defaultValue={extendedhash} />
                                            </p>

                                            <p>
                                                <label htmlFor="hashExtended" hidden>Hash Algorithm :</label>
                                                <input type="hidden" name="hash_algorithm" defaultValue="HMACSHA256" readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="mobileMode" hidden>mobileMode :</label>
                                                <input type="hidden" name="mobileMode" defaultValue="true" readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="hashExtended" hidden>Checkout option :</label>
                                                <input type="hidden" name="checkoutoption" defaultValue="combinedpage" readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="responseFailURL" hidden>responseFailURL :</label>
                                                <input type="hidden" name="responseFailURL" defaultValue="https://backend.theticketcompany.com.au/api/redirect" readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="responseSuccessURL" hidden>responseSuccessURL :</label>
                                                <input type="hidden" name="responseSuccessURL" defaultValue="https://backend.theticketcompany.com.au/api/redirect" readOnly="readonly" />
                                            </p>
                                            <p>
                                                <label htmlFor="transactionNotificationURL" hidden>transactionNotificationURL :</label>
                                                <input type="hidden" name="transactionNotificationURL" defaultValue="https://backend.theticketcompany.com.au/api/callback" readOnly="readonly" />
                                            </p>
                                            {/* <p> */}
                                            <div className="proceed-area  text-center1">
                                                <input type="submit" className="custom-button back-button" onClick={() => { Senddata(); proceed() }} id="submit" value="Checkout" />
                                            </div>
                                            {/* </p> */}
                                        </form>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ==========Movie-Section========== */}
                    {/* ==========Newslater-Section========== */}
                </div>



            </>
                || <><Modal open={session} showCloseIcon={false} center>
                    <p style={{ "margin-bottom": "59px" }}>Sorry, your session has expired</p>
                    <button onClick={closesession} className="cancel">Start again</button>
                </Modal></>} </>
    )
}