import './components/All.css';
import Registration from './components/RegistrationForm'
import Login from './components/Login'
import EventDetail from './components/Event_detail';
import PartyPlot from './components/bookingstep/Party_plot'
import { Navigate, Route, Routes } from 'react-router-dom';
import TermsConditions from './components/Terms_conditions';
import PrivacyPolicy from './components/Privacy-policy';
import PaymentRefundPolicy from './components/payment_refund_policy';
import Auditorium from './components/Auditorium';
import PageNotFound from './components/404';
import PartyPlotUser from './components/bookingstep/party_plot_user_details';
import AuditoriumUser from './components/Auditorium_user_details';
import ThankYou from './components/Thank_you';
import Share from './components/share'
import EditProfile from './components/Edit_profile'
import Myticket from './components/My_ticket';
import Plenary from './components/Plenary'
import Eventlist from './components/Eventlist'
import Career from './components/Career'
import Faq from './components/Faq'
import PastEvent from './components/Past_events'
import Contact from './components/Contact_us'
import AboutUs from './components/About_us'
import CollStatement from './components/Collection_Statement';
import PlenaryDome from './components/Plenary_test';
import Layout from './components/Layout';
import ForgetPassword from './components/ForgetPasword';
import ResetPassword from './components/ResetPassword';
import { Cookies } from 'react-cookie';
import Fiserv from './components/fiserv';
import Successpage from './components/success';
import Faildpage from './components/Transaction_fail'
import Checkout from './components/checkout'
import Checkemail from './components/checkemail'
import Home from './components/Home';
import Payment from './components/payment';
import ComboAuditoriumUser from './components/Combo_auditorium_user_details';

// ComboEvent code start
import ComboEventDetail from './components/Combo_event_detail';
import ComboEventlist from './components/Combo_eventlist';
import ComboAuditorium from "./components/Combo_auditorium";

// ComboEvent code end

const cookies = new Cookies();
function App() {

  let UserId = cookies.get('id');
  cookies.get('id');

  return (
    <>


      <Routes >
        <Route element={<Layout />}>

          <Route path='/registration' element={UserId ? <Navigate to="/" /> : <Registration />} />
          <Route path='/login' element={UserId ? <Navigate to="/" /> : <Login />} />
          <Route path='/event-detail/:event_name/:id' element={<EventDetail />} />
          <Route path='/combo-event-detail/:event_name/:id' element={<ComboEventDetail />} />
          <Route path='/terms-condition' element={<TermsConditions />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/payment-return-policy' element={<PaymentRefundPolicy />} />
          <Route path='/*' element={<PageNotFound />} />
          <Route path='/thankyou' element={<ThankYou />} />
          <Route path='/checkemail' element={<Checkemail />} />
          <Route path='/responseSuccessURL' element={<Successpage />} />
          <Route path='/responseFailURL' element={<Faildpage />} />
          <Route path='/share' element={<Share />} />
          <Route path='/my-profile/edit' element={<EditProfile />} />
          <Route path='/my-ticket' element={<Myticket />} />
          <Route path='/plenary' element={<Plenary />} />
          <Route path='/career' element={<Career />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/past-events' element={<PastEvent />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/collection-statement' element={<CollStatement />} />

          <Route path='/forgotpassword' element={UserId ? <Navigate to="/" /> : <ForgetPassword />} />
          <Route path='/resetpassword' element={UserId ? <Navigate to="/" /> : <ResetPassword />} />
        </Route>

        <Route path='/' element={<Home />} />
        <Route path='/events' element={<Eventlist />} />
        <Route path='/combo-events' element={<ComboEventlist />} />
        <Route path='/fiserv' element={<Fiserv />} />
        <Route>
          <Route path='/seat-booking' element={<Auditorium />} />
          <Route path='/combo-seat-booking' element={<ComboAuditorium />} />

          <Route path='/partyplotuser' element={<PartyPlotUser />} />
          <Route path='/bookingstep/select-ticket' element={<PartyPlot />} />
          <Route path='/auditoriumusers' element={<AuditoriumUser />} />
          <Route path='/comboauditoriumusers' element={<ComboAuditoriumUser />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/Plenary-dome' element={<PlenaryDome />} />

        </Route>

      </Routes>



    </>
  );
}

export default App;
