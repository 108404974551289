import React, { useEffect, useState } from "react";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Privacy() {


    return (
        <>
            <main >
                <section className="termsservice  gt__bg-image categorysec categorysec2 categorysec3" >
                    <div className="container">
                        <div className="ctextinfo">
                            <h1>privacy-policy</h1>
                            <p>
                                At The Ticket Company, we value and respect the privacy of your personal information. This policy explains the ways in which we collect, use, and protect your personal information.
                            </p>
                            <h4 className="mpoint">Notice and Disclosure <span >(Privacy Act 1998)</span></h4>
                            <p>
                                <h4 className="mpoint">What is Personal Information and why do we collect it?</h4>

                                Personal information is any information that identifies you as an individual or that can be used to identify you. We collect personal information in various forms, including through telephone, email, our website (<a href="https://www.theticketcompany.com.au">www.theticketcompany.com.au</a>), cookies, and other sources.
                            </p>
                            <p>
                                We collect personal information in order to provide our services to you with the utmost care and security. This allows us to provide information to our clients and event organisers. When we collect personal information, we will inform you why we require such information and how it will be used.
                            </p>
                            <p>
                                We may also use your personal information for secondary purposes that are related to the primary purpose, and which you would reasonably expect. You may unsubscribe from our mailing/marketing lists at any time by contacting us at <a href="mailto:support@theticketcomapny.com.au">support@theticketcomapny.com.au</a>.
                            </p>
                            <p>
                                <h4>Security Precaution</h4>
                                We have security measures in place to protect against the loss, misuse, and alteration of the information under our control. Our website uses industry-standard secure server software (SSL) for secure transactions.
                            </p>


                            <p>
                                <h4>Cookies</h4>
                                We use cookies on an anonymous basis to track the pages you have visited. We do not correlate this information with personal data of any user. You must have your browser configured to automatically accept cookies to purchase tickets from our website.
                            </p>
                            <p>
                                <h4>Third-Party Links and Policies</h4>
                                We are not responsible for the policies or practices of third-party websites that may be linked to our website.
                            </p>
                            <p>
                                <h4>How to Contact Us </h4>

                                If you have any questions regarding our privacy policy, please feel free to contact us by e-mail at <a href="mailto:support@theticketcompany.com.au">support@theticketcompany.com.au</a> or call us at <a href="tel:+61403479961">+61403479961</a>.
                            </p>
                            <p>
                                This policy may change from time to time, so please check back periodically for updates.
                            </p>

                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}