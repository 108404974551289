import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Logo from '../images/The_ticket_company_logo.svg';
import User from "../images/user.svg"
import Users from "../images/user.svg"
import City from './au.json'
import { Cookies } from 'react-cookie'
import sortBy from "lodash/sortBy";
import secureLocalStorage from "react-secure-storage";
import MyTicket from "../images/my-ticket.svg";
import MyProfile from "../images/my-profile.svg";
import LogOut from "../images/logout.svg";

export default function Header() {

    // Search bar
    const [searchTerm, setsearchTerm] = useState('');
    const [events, setEvents] = useState();



    // Header Sticky Code Start

    const [navbar, setNavbar] = useState(false);
    const [Longitude, setLongitude] = useState(false);
    const [Latitude, setlLatitude] = useState(false);
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", changeBackground);
        return () => window.removeEventListener("scroll", changeBackground);
    });

    // Header Sticky Code End


    // LogOut Buton show and hide code start

    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => setShowResults(!showResults)
    const Results = () => (
        <div className="afterlogin">
            <ul>
            <li><Link to="/my-profile/edit" onClick={handleScroll} className="mypro"><img src={MyProfile}></img><p>My Profile</p></Link></li>
                <li><Link to="/my-ticket" className="mypro" onClick={handleScroll}><img src={MyTicket}></img><p>My Ticket</p></Link></li>
                <li><Link to="/" onClickCapture={logout} className="mypro"><img src={LogOut}></img><p>Logout</p></Link></li>

            </ul>
        </div>
    )
    const cookies = new Cookies();
    let UserId = cookies.get('id');
    // secureLocalStorage.getItem('accesstoken');
    cookies.get('accesstoken');
    cookies.get('name');
    cookies.get('id');
    const navigates = useNavigate();
    const logout = () => {

        cookies.remove('accesstoken')
        cookies.remove('name')
        cookies.remove('id')
        // window.location = "/";
        navigates();
    };
    // LogOut Buton show and hide code End


    let UserName = cookies.get('name');


    // settings API calling code start
    const config = {
        headers: { 'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh' },
        params: {
            is_pagination: 1,
            skip: 0,
            limit: 5,
            is_desc: 1
        }
    }
    const [data, setData] = useState('');
    const getsetting = () => {
        axios.get('https://backend.theticketcompany.com.au/api/settings', config).then((response) => {

            // console.log(response.data.response_data);
            setData(response.data.response_data);
        }).catch((error) => {
            // console.log(error);
        });
    };
    useEffect(() => {
        getsetting();
        window.addEventListener("scroll", changeBackground);
        return () => window.removeEventListener("scroll", changeBackground);
    }, []);

    // Setting API Calling End

    // Page position scroll code start
    const handleScroll = () => {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    // Page position scroll code end

    const [datas, setDatas] = useState('');
    const [list, setlist] = useState('');
    const getAllData = () => {
        axios.get('https://backend.theticketcompany.com.au/api/event', config).then((response) => {

            setDatas(response.data.response_datas);

        }).catch((error) => {
            // console.log(error);
        });
    };
    useEffect(() => {
        getAllData();
        getuserdetails();

    }, []);


    // City Location code
    const [cityname, setcityname] = useState('');
    const handleCity = (e) => {
        const getcityId = e.target.value;
        setcityname(getcityId);
        const getLat = City.find(country => country.city === getcityId).lat;
        const getLng = City.find(country => country.city === getcityId).lng;
        // console.log(getLat);
        // setLongitude(getLat);
        // setlLatitude(getLng);
        secureLocalStorage.setItem("getLat", getLat);
        secureLocalStorage.setItem("getLng", getLng);
    }

    const [isAccordclose1, setAccordclose1] = useState(true);

    const [showMe1, setShowMe1] = useState(true);

    useEffect(() => {
        if (window.innerWidth > 747) {
            setShowMe1(false)
        }

    });
    const handleToggle1 = () => {
        setAccordclose1(!isAccordclose1);
        setShowMe1(!showMe1);
    };
    // Mobile Responsive code End

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];
    const sort = sortBy(City, "label");


    const [selectedOption, setSelectedOption] = useState(null);
    const [search_bar, setSearch] = useState(null);

    // get username
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');

    const getuserdetails = () => {
        axios.get(`https://backend.theticketcompany.com.au/api/getuser?user_id=${UserId}`,config).then((result) => {

            // console.log(result.data.response_data.first_name);
            setFname(result.data.response_data.first_name);
            setLname(result.data.response_data.last_name);





        }).catch((error) => {
            // console.log(error);
        });

    };

    return (
        <>
            <header className='gt__fixed-heder' >

                <div className="gt__header-wrap d-flex d-flex-wrap justify-content-between align-items-center inner_header">
                    <div className="gt__header-left">
                        <NavLink to="/" onClick={handleScroll} className="gt__header-logo"> <img src={Logo} alt="The_ticket_company" /></NavLink>
                        <div id="mobilemenu" onClick={handleToggle1} className={`${isAccordclose1 ? "submenu" : "closebtn"}`}></div>
                    </div>

                    <div className="gt__header-right d-flex d-flex-wrap justify-content-between align-items-center">

                        <div className="gt__header-menu submenu" style={{ display: !showMe1 ? "block" : "none" }}>
                            <ul className="d-flex d-flex-wrap align-items-center">
                                <li> <Link to="/events">Events</Link></li>
                                <li> <Link to="/combo-events">Combo Events</Link></li>
                                {/* <li><a href="#">Offers</a></li> */}
                            </ul>
                        </div>


                        <div className="gt__header-socialsec d-flex d-flex-wrap justify-content-between align-items-center">

                            <div className="gt__user-details gt__img-middlesubmenu" style={{ display: !showMe1 ? "block" : "none" }} onClick={onClick}>
                                {fname &&
                                    <NavLink to="#">
                                        <img src={User} alt="search" width={24} height={24} />
                                        <span className="mypro">{fname}</span>

                                    </NavLink>

                                    || !fname &&

                                    <NavLink to="/login" onClick={handleScroll}>
                                        <img src={User} alt="search" width={24} height={24} />
                                        <span className="mypro">{'Sign in'} </span>
                                    </NavLink>
                                }
                                {fname && <div>{showResults ? <Results /> : null}</div>}
                            </div>

                        </div>
                    </div>
                </div>

            </header>
        </>
    )
}
