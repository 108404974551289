import React, { useEffect, useState } from "react";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
const CryptoJS = require("crypto-js");


export default function payment() {


    //const dt = new Date();


    let d = new Date();
    // console.log(d);

    let dts = d.toLocaleString(undefined, { timeZone: "Australia/Sydney" });
    let dt = new Date(dts);
    // console.log(dt);
    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
    let newdate = `${dt.getFullYear()}:${padL(dt.getMonth() + 1)}:${padL(dt.getDate())}-${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(dt.getSeconds())}`
    // console.log(newdate);
    const current_date = new Date();
    const time = new Date().getTime();



    let sharedSecret = "mDQaFg57tq";
    let separator = "|";
    let storeId= "3650650452";
    //$timezone= "Europe/London";
    let timezone= "Australia/Sydney";
    let txntype= "sale";
    let checkoutoption = "combinedpage";
    let URL = "https://www.softnoesis.com/blog/responseFailURL.php";
    let failure = "https://www.softnoesis.com/blog/notificationURL.php";
    let success = "https://www.softnoesis.com/blog/responseSuccessURL.php";
    // let stringToHash = ;
//  $stringToHash = '13.00'. $separator .$checkoutoption. $separator.'036'.$separator.'HMACSHA256'.$separator.$oid.$separator.'https://www.softnoesis.com/blog/responseFailURL.php'.$separator.'https://www.softnoesis.com/blog/responseSuccessURL.php'.$separator . $storeId.$separator.$timezone.$separator.'https://www.softnoesis.com/blog/notificationURL.php'.$separator .$current_date. $separator . $txntype;
const stringToHash = "13.00"+separator+checkoutoption+separator+"036"+separator+"HMACSHA256"+separator+'124578'+separator+"https://www.softnoesis.com/blog/responseFailURL.php"+separator+"https://www.softnoesis.com/blog/responseSuccessURL.php"+separator+storeId+separator+timezone+separator+"https://www.softnoesis.com/blog/notificationURL.php"+separator+newdate+separator+txntype;
// console.log(stringToHash);

var hashHMACSHA256 = CryptoJS.HmacSHA256(stringToHash, sharedSecret);
var extendedhash = CryptoJS.enc.Base64.stringify(hashHMACSHA256);
// console.log(extendedhash);
    return (
        <>
            <form method="post" action="https://test.ipg-online.com/connect/gateway/processing">
                <fieldset>
                    <legend>IPG Connect Request Details</legend>
                    <p>
                        <label htmlFor="oid">oid:</label>
                        <input type="text" name="oid" defaultValue='124578' readOnly="readonly" />
                    </p>
                    <p>
                        <label htmlFor="storename">Store ID:</label>
                        <input type="text" name="storename" defaultValue={3650650452} readOnly="readonly" />
                    </p>
                    <p>
                        <label htmlFor="timezone">Timezone:</label>
                        <input type="text" name="timezone" defaultValue="Australia/Sydney" readOnly="readonly" />
                    </p>
                    <p>
                        <label htmlFor="chargetotal">Transaction Type:</label>
                        <input type="text" name="txntype" defaultValue="sale" readOnly="readonly" />
                    </p>
                    <p>
                        <label htmlFor="chargetotal">Transaction Amount:</label>
                        <input type="text" name="chargetotal" defaultValue="13.00" readOnly="readonly" />
                    </p>
                    <p>
                        <label htmlFor="currency">Currency (see ISO4217):</label>
                        <input type="text" name="currency" defaultValue="036" readOnly="readonly" />
                    </p>
                    <p>
                        <label htmlFor="txndatetime">Transaction DateTime:</label>
                        <input type="text" name="txndatetime" defaultValue={newdate} />
                    </p>
                    {/* <p> */}
                    {/*?php
    $sharedSecret = "mDQaFg57tq";
    $separator = "|";
    $storeId= "3650650452";
    //$timezone= "Europe/London";
    $timezone= "Australia/Sydney";
    $txntype= "sale";
    $checkoutoption = "combinedpage";
    $stringToHash = '13.00'. $separator .$checkoutoption. $separator.'036'.$separator.'HMACSHA256'.$separator.$oid.$separator.'https://www.softnoesis.com/blog/responseFailURL.php'.$separator.'https://www.softnoesis.com/blog/responseSuccessURL.php'.$separator . $storeId.$separator.$timezone.$separator.'https://www.softnoesis.com/blog/notificationURL.php'.$separator .$current_date. $separator . $txntype;
    echo "<pre*/}"
                    {/* ;print_r($stringToHash);echo "";
                        $hash = base64_encode(hash_hmac('sha256', $stringToHash, $sharedSecret, true));
                        echo "</p><pre>";print_r($hash);echo "</pre>";
                    ?&gt; */}
                    <label htmlFor="hashExtended">Hash Extended:</label>
                    <input type="text" name="hashExtended" defaultValue={extendedhash} />
                    <p />
                    <p>
                        <label htmlFor="hashExtended">Hash Algorithm :</label>
                        <input type="text" name="hash_algorithm" defaultValue="HMACSHA256" readOnly="readonly" />
                    </p>
                    <p>
                        <label htmlFor="hashExtended">Checkout option :</label>
                        <input type="text" name="checkoutoption" defaultValue="combinedpage" readOnly="readonly" />
                    </p>
                    <p>
                        <label htmlFor="responseFailURL">responseFailURL :</label>
                        <input type="text" name="responseFailURL" defaultValue="https://www.softnoesis.com/blog/responseFailURL.php" readOnly="readonly" />
                    </p>
                    <p>
                        <label htmlFor="responseSuccessURL">responseSuccessURL :</label>
                        <input type="text" name="responseSuccessURL" defaultValue="https://www.softnoesis.com/blog/responseSuccessURL.php" readOnly="readonly" />
                    </p>
                    <p>
                        <label htmlFor="transactionNotificationURL">transactionNotificationURL :</label>
                        <input type="text" name="transactionNotificationURL" defaultValue="https://www.softnoesis.com/blog/notificationURL.php" readOnly="readonly" />
                    </p>
                    <p>
                        <input type="submit" id="submit" defaultValue="Submit" />
                    </p>
                </fieldset>
            </form>
        </>
    )
}