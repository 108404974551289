// ** create-user.component.js ** //
import React, { Component, useEffect, useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Cookies } from 'react-cookie';
import UserSignin from '../images/user-signin.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';





export default function Login({ isAuthenticated }) {



    const cookies = new Cookies();
    const [error, setError] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [data, setData] = useState({
        email: "",
        password: "",

    });
    const {
        formState: { errors },
    } = useForm();

    const handleChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    };
    // const lastLocation = useLocation();
    const navigates = useNavigate();
    // const history = useHistory();

    const handleSubmit = (e) => {
        if (data.email == "") {
            // console.log("not available")
        }
        e.preventDefault();
        const userData = {
            email: data.email,
            password: data.password
        };
        // console.log(userData);

        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',
            // 'Authorization': `Bearer ${secureLocalStorage.getItem('accesstoken')}`

        }
        axios.post('https://backend.theticketcompany.com.au/api/login', userData, {
            headers: headers
        })
            .then((res) => {
                // console.log(res.data);
                if (res.response_data !== null) {
                    if (res.data.success == true) {
                        navigates(-1);
                    }

                    else {
                        if (data.email == "") {
                            setEmail("Please enter your email")
                        }
                        else if (data.password == "") {
                            setPassword('Please enter your password')
                        }
                        else if (res.data.success == false && data.email !== null && data.password !== null) {
                            setError("Invalid email or password")
                        }
                    }
                }


                cookies.set("accesstoken", res.data.response_data.accesstoken);
                cookies.set("id", res.data.response_data.User.id);
                // console.log(res.data.response_data.User.id);
                cookies.set("name", res.data.response_data.User.name);
                cookies.set("email", res.data.response_data.User.email);
                cookies.set("user_role", res.data.response_data.User.user_role);

                // console.log(cookies);
                // console.log(res.data)

            }).catch((error) => {
                // console.log(error)
            });

    };

    // hide and show password start
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }
    // hide and show password End
    return (
        <>
            <main>
                {/*start SIGN IN sec*/}

                <section className="gt__signin-sec gt__user-sec gt__bg-image gt__bg-image1 gt__signin-sec1">
                    <h1 className="txt-center">SIGN IN</h1>
                    <div className="gt__signin-wrap gt__user-wrap">
                        <div className="gt__user-icn">
                            <img src={UserSignin} alt="user" width={40} height={40} />
                        </div>
                        <div className="gt__signin-inner gt__user-inner">

                            <div className="gt__form-block">
                                <h4 className="gt__user-title txt-center"><span> sign in with email</span></h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="errorMsg invalid-feedback">{error}</div>

                                    <div className="gt__form-grp">
                                        <label htmlFor="email">Email</label>
                                        <input type="text" name="email" value={data.email}
                                            onChange={handleChange} />

                                        <div className="errorMsg invalid-feedback">{email}</div>
                                    </div>
                                    <div className="gt__form-grp gt__form-pass">
                                        <label htmlFor="psw">Password</label>
                                        <input type={showPassword ? 'text' : 'password'} name="password" value={data.password}
                                            onChange={handleChange} id="input_passowrd"/>
                                        <FontAwesomeIcon
                                            icon={showPassword ? faEyeSlash : faEye}
                                            className="toggle-password"
                                            id="togglePassword"
                                            onClick={togglePasswordVisibility}
                                        />

                                        <div className="errorMsg invalid-feedback">{password}</div>
                                    </div>

                                    <div className="gt__form-grp d-flex d-flex-wrap justify-content-between align-items-center">
                                        <div className="gt__check-box">
                                            <label className="containerc">
                                                <input type="checkbox" defaultChecked="checked" />
                                                <span className="checkmark" />Remember me
                                            </label>
                                        </div>
                                        <div className="gt__forgot-pass">
                                            <NavLink to="/forgotpassword"> Forgot Password </NavLink>
                                        </div>
                                    </div>
                                    <div className="gt__form-grp gt__form-btn txt-center">
                                        <button type="submit" className="gt__btn"  >Login</button>

                                    </div>
                                    <div className="gt__form-grp gt__reg-link txt-center">
                                        <p>Not registerd yet?<NavLink to="/registration"> Create an Account </NavLink></p>
                                    </div>
                                </form></div>
                        </div>
                    </div>
                </section>
                {/*end SIGN IN sec*/}
            </main>
        </>
    )
}