export default function About() {

    return (
        <section className="termsservice  gt__bg-image categorysec categorysec2 categorysec3" >
            <div className="container">
                <div className="ctextinfo">
                    <h1>About Us</h1>
                    <p>
                    The Ticket Company offers a comprehensive range of services for event ticket sales and marketing. With both online and offline ticket sales options, customers have flexibility in how they purchase tickets. The option for reserved seating arrangements also provides a personalized touch for customers who want to choose their own seats.
                    </p>
                    <p>
                    In terms of security, the use of QR code ticketing and scanning solutions adds an extra layer of protection for customers' personal information and helps to prevent fraud. This also makes the process of checking in and entering the event faster and more efficient.
                    </p>
                    <p>
                    The Ticket Company's graphic design services and event marketing options offer a one-stop shop for event organizers who want to promote their events. By utilizing various social media platforms and marketing channels, event organizers can reach a broader audience and increase attendance at their events. Additionally, The Ticket Company's printing solutions for posters and flyers can help event organizers create eye-catching promotional materials to further market their events.
                    </p>
                    <p>
                        *Available for online ticketing Australia wide.
                    </p>
                </div>
            </div>
        </section>

    )
}