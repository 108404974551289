// ** create-user.component.js ** //
import React, { Component, useEffect, useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Cookies } from 'react-cookie'
import { Modal } from 'react-responsive-modal';



export default function ResetPassword() {

    // popup model
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    let cookies = new Cookies();

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is mandatory')
            .min(3, 'Password must be at 3 char long')
            .matches(/^\S*$/, 'Whitespace is not allowed'),

        cpassword: Yup.string()
            .required('CPassword is mandatory')
            .oneOf([Yup.ref('password')], 'CPasswords does not match')
            .matches(/^\S*$/, 'Whitespace is not allowed'),
            passcode: Yup.string()
            .required('passcode is mandatory')
            // .oneOf([Yup.ref('password')], 'Passwords does not match'),

    });

    let UserId = cookies.get('id');

    // let UserName = secureLocalStorage.getItem('name');
    // let Email_id = secureLocalStorage.getItem('email');

    let UserName = cookies.get('name');
    let Email_id = cookies.get('email');

    const formOptions = { mode: 'onChange', resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const [data, setData] = useState()
    function onSubmit(val) {
        // console.log(val);

        const body = new FormData();

        body.append("random_string", val.passcode);
        body.append("password", val.cpassword);

        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


        }
        axios.post(`https://backend.theticketcompany.com.au/api/change-password`, body, {
            headers: headers
        })
            .then((res) => {
                if (res.data.status == true) {

                    window.location = "/login";
                }

                setData(res.data.message);
                setOpen(true)

            }).catch((error) => {
                // console.log(error)
            });
    }

    return (
        <>
            <main className='myticket1'>

                <Modal open={open} onClose={onCloseModal} center>

                    <h4 className='htext'> {data}</h4>
                    <br></br>
                    {/* <Link to="/" className="cancel">Ok</Link> */}
                    <button onClick={onCloseModal} type="submit" className="ok">ok</button>
                </Modal>




                <section className="gt__signup-sec gt__user-sec gt__bg-image gt__bg-image1">

                    <div className="gt__signin-wrap gt__user-wrap padding">

                        <div className="gt__signin-inner gt__user-inner">

                            <div className="gt__form-block">
                                <h4 className="gt__user-title txt-center"><span>Reset Password</span></h4>
                                <form method="post" name="userRegistrationForm" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" autoComplete="off" >
                                    <div className="gt__fname-block margin">
                                        <label htmlFor="email"> Enter Passcode</label>

                                        <input type="text" name="passcode" {...register('passcode')} autoComplete="off" />

                                        <div className="invalid-feedback">{errors.passcode?.message}</div>

                                    </div>


                                    <div className="gt__fname-block margin">
                                        <label htmlFor="email">New Password</label>

                                        <input type="password" name="password" {...register('password')} autoComplete="off" />
                                        <div className="invalid-feedback">{errors.password?.message}</div>

                                    </div>
                                    <div className="gt__fname-block margin">
                                        <label htmlFor="email">Confirm Password</label>

                                        <input type="password" name="email" {...register('cpassword')} />

                                        <div className="invalid-feedback">{errors.cpassword?.message}</div>

                                    </div>


                                    <div className=" gt__form-btn txt-center margin">

                                        <button type="submit" className="gt__btn">Submit</button>
                                    </div>


                                </form></div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}