export default function Faq() {

    return (

        <section className="termsservice  gt__bg-image categorysec categorysec2 categorysec3" >
            <div className="container">
                <div className="ctextinfo">
                    <h1>FAQ's</h1>
                    <p>
                        <h4 className="mpoint">1. What is an e-ticket?</h4>

                        An e-ticket is an event ticket sent to you through email, and it is printable at the end of placing your transaction through our website.

                    </p>
                    <p>
                        <h4 className="mpoint">2. Do I need to print out my tickets?</h4>
                        Most organizers prefer that you print your tickets and present them upon arrival at the event. This helps facilitate faster entry management procedures. Please check the details on your ticket under ‘Ticketing Notes’ to see if the Organizer accepts electronic presentation of tickets (via phone/tablet), if they need to be printed, or if tickets do not need to be presented for certain events.


                    </p>
                    <p>
                        <h4 className="mpoint">3. I did not receive and e-ticket confirmation, what shall I do?</h4>
                        If you have not received your e-ticket confirmation, please check your junk and spam folders in the email address you provided during your purchase. Our tickets are sent from the email address <a href="mailto:noreply@theticketcompany.com.au">noreply@theticketcompany.com.au</a>. If you still can't find your tickets, please contact us by calling <a href ="tel:+6140 347 9961">+6140 347 9961</a> or emailing support@theticketcomapny.com.au. We will resend your confirmation, as this may be due to an incorrect email address entered during the purchasing process.

                    </p>

                    <p>
                        <h4 className="mpoint">4. Is there a discount available for group bookings?</h4>
                        We occasionally offer discounts for group bookings of over 10 people. If you're interested in purchasing a large number of tickets, please contact us at <a href="tel:+6140 347 9961">+6140 347 9961</a> or email us at <a href="mailto:info@theticketcompany.com.au">info@theticketcompany.com.au</a> for more information.

                    </p>
                    <p>
                        <h4 className="mpoint">5. I am having trouble making a payment by credit / debit card. What should I do?</h4>
                        If you are having trouble making a payment by credit card, please double-check the details you are entering and try submitting your card again. If the problem persists, you should contact your credit / debit card supplier for further information. They may be able to assist you in resolving any issues with your card or account. If you continue to experience problems, please feel free to contact us on <a href="tel:+6140 347 9961">+6140 347 9961</a> or email us at <a href="mailto:support@theticketcompany.com.au">support@theticketcompany.com.au</a>  for further assistance.


                    </p>
                    <p>
                        <h4 className="mpoint">6. Do you have an age limit for the events you sell tickets for?</h4>
                        Please note that any age restrictions are specific to each event and are clearly mentioned next to the event details. Kindly make sure to check this information before making your purchase.
                    </p>

                    <p>
                        <h4 className="mpoint">7. Does The Ticket Company promote and organize events?</h4>
                        No, The Ticket Company is a service that operates as an online sales agent for event organizers and promoters. We do not promote or organize any events ourselves.

                    </p>
                    <p>
                        <h4 className="mpoint">8. Can I cancel my purchased tickets?</h4>
                        Unfortunately, we do not offer cancellations or refunds for tickets. If you are unable to attend an event, you may contact the event organizer directly to inquire about their cancellation policy. If the organizer agrees to a refund, the booking fee per ticket will be deducted from the refund amount. The booking fee varies depending on the ticket price and the event.

                    </p>
                    <p>
                        <h4 className="mpoint">9. Will there be any additional booking or handling fees when purchasing tickets online? </h4>
                        Booking and handling fees may be applied to a small number of events and will be clearly displayed. While we strive to avoid the need for booking fees, a small transaction fee will be charged per attendee during the checkout process.
                    </p>
                    <p>
                        <h4 className="mpoint">10. How can I sell tickets for my event on <a href="https://www.theticketcompany.com.au" target="_blank">www.theticketcompany.com.au</a>?</h4>
                        As an event organizer, we offer various discounts and incentives to help you promote your event. Please contact our event specialists at <a href="tel:+61403479961">+61403479961</a> or email us at <a href="mailto:info@theticketcompany.com.au">info@theticketcompany.com.au</a> to learn more.
                    </p>
                </div>
            </div>
        </section>

    )
}