import React, { useEffect, useState } from "react";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import { animateScroll as scroll } from "react-scroll";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";

export default function Home() {
    const config = {
        headers: { 'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh' },
        params: {
            is_pagination: 1,
            skip: 0,
            limit: 5,
            is_desc: 0
        }
    }
    // Product details page code start

    const navigate = useNavigate();

    // Product details page code end

    const baseURL = "https://backend.theticketcompany.com.au/image/";
    const bannerURL = "https://backend.theticketcompany.com.au/image/sliders/";

    const [data, setData] = useState('');
    const [datas, setDatas] = useState('');
    const getAllData = () => {
        axios.get('https://backend.theticketcompany.com.au/api/event', config).then((response) => {

            // console.log(response.data.response_data);
            setData(response.data.response_data);

        }).catch((error) => {
            // console.log(error);
        });
    };
    const getbanner = () => {
        axios.get('https://backend.theticketcompany.com.au/api/slider', config).then((response) => {

            // console.log(response.data.response_data);
            setDatas(response.data.response_data);

        }).catch((error) => {
            // console.log(error);
        });
    };
    useEffect(() => {
        getAllData();
        getbanner();

    }, []);

    var settings = {
        dots: true,
        infinite: true,
        speed: 350,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
        // centerMode: true,
        // variableWidth: true
    };




    return (
        <>
            <main>
                <section class="thanyoupage">
                    <div class="d-flex justify-content-center">
                        <div class="w-100 text-center thanktexts">
                            <div class="micon"></div>
                            <h3>Thank You for booking with us!</h3>
                            <p>You will get a confirmation message in email shortly</p>
                            <Link to="/"><a class="cbtn">Back To Home Page</a></Link>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}