import checkdetail from "./checkdetail"
import { useEffect, useState } from "react";
import { Cookies } from 'react-cookie'
import Slider from 'react-slick'
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import backButton from 'browser-back-button';
import secureLocalStorage from "react-secure-storage";
import ExpiredStorage from "expired-storage";
import { Modal } from 'react-responsive-modal';


export default function Success() {

    // sesssion time out
    const navigate = useNavigate();

    const [refreshData, setRefreshData] = useState(false);
    const expiredStorage = new ExpiredStorage();
    const item = expiredStorage.getItem("test");


    useEffect(() => {

        const item = expiredStorage.getItem("test");

    }, [refreshData])

    const [session, setsession] = useState(true);
    const closesession = () => {

        setsession(false);
        navigate("/")

    }

    const [wer, setOpen] = useState();
    // console.log(wer);
    // local storage value
    let ticketBooking_id = localStorage.getItem('ticketBooking_id');
    let checkoutId = localStorage.getItem('chekout_id');
    let pid = localStorage.getItem('pid');
    let gross_amount = localStorage.getItem("gross_amount");
    let voucher_id = localStorage.getItem("voucher_id");
    let subtotal_amount = localStorage.getItem("subtotal_amount");
    let tax_amount = localStorage.getItem("tax_amount");
    let net_amount = localStorage.getItem("net_amount");
    // console.log(oid);
    let event_name = localStorage.getItem('event_name');
    // local storage value end
    let oid = localStorage.getItem("oid");
    let voucher_amount = localStorage.getItem("descount_amount");
    // console.log(oid);

    const cookies = new Cookies();
    let UserId = cookies.get('id');

    let event_type = localStorage.getItem('event_type') || localStorage.getItem('event_type');
    let combo_event_id = localStorage.getItem('combo_event_id');
    // let pid = cookies.get('pid');
    // let gross_amount =  cookies.get("gross_amount");
    // let voucher_id = cookies.get("voucher_id");
    // let subtotal_amount = cookies.get("subtotal_amount");
    // let tax_amount = cookies.get("tax_amount");
    // let net_amount = cookies.get("net_amount");

    const checkoutdetail = () => {


        const body = new FormData();
                    body.append("user_id", UserId);
                    body.append("gross_amount", gross_amount);
                    body.append("oid", oid);
                    body.append("voucher_id", voucher_id);
                    body.append("subtotal_amount", subtotal_amount);
                    body.append("tax_amount", tax_amount);
                    body.append("net_amount", net_amount);
                    body.append("user_id", UserId);
                    body.append("pid", pid);
                    body.append("voucher_amount", voucher_amount);
                    body.append("combo_event_id", combo_event_id);
                    if (event_type == "Combo") {
                    body.append("is_combo", 1);

                    }
                    else{
                    body.append("is_combo", 0);

                    }
// console.log('body', body)

        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',

        }

        axios.post('https://backend.theticketcompany.com.au/api/payment', body, {
            headers: headers
        })
            .then((res) => {
                if (res.data.status == true) {
                    localStorage.removeItem('oid');
                    localStorage.clear();
                }
                else {
                    // console.log(res.data.status)
                    localStorage.removeItem('oid');
                    localStorage.clear();
                }

            }).catch((error) => {
                // console.log(error)
            });



    }



    useEffect(() => {
        checkoutdetail();
    }, [])

    return (<>{item &&
        <main>
            <section className="thanyoupage">
                <div className="d-flex justify-content-center">
                    <div className="w-100 text-center thanktexts">
                        <div className="micon"></div>
                        <h3>Thank You for booking with us!</h3>
                        <p>You will get a confirmation message in email shortly</p>
                        <Link to="/"><a className="cbtn">Back To Home Page</a></Link>
                    </div>
                </div>
            </section>
        </main> || <><Modal open={session} showCloseIcon={false} center>
            <p style={{ "margin-bottom": "59px" }}>Sorry, your session has expired</p>
            <button onClick={closesession} className="cancel">Start again</button>
        </Modal></>
    }
    </>)


}