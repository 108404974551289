import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  FacebookIcon,
  EmailIcon,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookShareCount
} from "react-share";

export default function RSSUsage() {
  const href = window.location.href;



  return (<>

    <TwitterShareButton
      url={href}
    >
      <TwitterIcon size={32} round={true} />
    </TwitterShareButton>


    <LinkedinShareButton
      url={href}
    >
      <LinkedinIcon size={32} round={true} />
    </LinkedinShareButton>


    <WhatsappShareButton
      url={href}
    >
      <WhatsappIcon size={32} round={true} />

    </WhatsappShareButton>

    <FacebookShareButton
      url={href}
    >
      <FacebookIcon size={32} round={true} />

    </FacebookShareButton>



    <EmailShareButton
      url={href}
    >
      <EmailIcon size={32} round={true} />
    </EmailShareButton>


  </>
  )
}