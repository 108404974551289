import React, { Component, useEffect, useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Cookies } from 'react-cookie'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';



export default function UserDetails() {

    // popup model
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    //loader
    const [loader, setLoader] = useState(false);

    let cookies = new Cookies();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const nameRegExp = /[A-Za-z]/
    const nameRegEx = /[A-Za-z]{3}/

    const validationSchema = Yup.object().shape({

        firstname: Yup.string()
            .required('Firstname is required')
            .matches(nameRegExp, 'Only alphabetic characters is allowed')
            .matches(nameRegEx, '  Length should be at least 3'),
        lastname: Yup.string()
            .required('Lastname is required')
            .matches(nameRegExp, 'Only alphabetic characters is allowed')
            .matches(nameRegEx, 'Length should be at least 3'),
        mobile: Yup.string()
            .required('Phone number is required')

            .matches(phoneRegExp, 'Only number is required')
        ,
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
    });

    let UserId = cookies.get('id');

    const [datas, setDatas] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const headers = {
        'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',
    }

    const getuserdetails = () => {
        axios.get(`https://backend.theticketcompany.com.au/api/getuser?user_id=${UserId}`,{headers: headers}).then((result) => {

            setFname(result.data.response_data.first_name);
            setLname(result.data.response_data.last_name);
            setDatas(result.data.response_data.users_infos);
            // console.log(result.data.response_data.users_infos);

        }).catch((error) => {
            // console.log(error);
        });

    };


    useEffect(() => {
        getuserdetails();
    }, [open])



    // let UserName = secureLocalStorage.getItem('name');
    // let Email_id = secureLocalStorage.getItem('email');

    let UserName = cookies.get('name');
    let Email_id = cookies.get('email');

    const formOptions = { mode: 'onChange', resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const [data, setData] = useState()
    function onSubmit(val) {

        const body = new FormData();

        body.append("firstname", val.firstname);
        body.append("lastname", val.lastname);

        body.append("mobile_number", val.mobile);
        body.append("id", UserId);
        body.append("email", val.email);
        body.append("address", val.address);
        body.append("suburb", val.suburb);
        body.append("state", val.state);
        body.append("postcode", val.postcode);


        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


        }
        axios.post(`https://backend.theticketcompany.com.au/api/editprofile?id=${UserId}`, body, {
            headers: headers
        })
            .then((res) => {
                if (UserId) {
                    // console.log(res.data.message);
                    setData(res.data.message);
                    setOpen(true)

                }
                else {
                    window.location = "/login";
                }
            }).catch((error) => {
                // console.log(error)
            });



    }


    // Edit password code start here
    const [password, setpassword] = useState(" ");

    const [newpassword, setnewpassword] = useState(" ");

    function passwords(e) {
        setpassword(e.target.value);
        setnewpassword(e.target.value);
    }

    function onEdit(e) {
        // console.log(e);




        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


        }
        axios.post(`https://backend.theticketcompany.com.au/api/editpassword?id=${UserId}&password=${password}&new_password=${newpassword}`, [],{
            headers: headers

        })
            .then((res) => {
                if (res.data.status_code == "200") {
                    // console.log(res.data.message);
                    setData(res.data.message);
                    setOpen(true)
                }
                else {
                    // console.log(res.data.message);
                    setData(res.data.message);
                    setOpen(true)
                }
                // setOpen(true)


            }).catch((error) => {
                // console.log(error)
            });



    }

    // Edit password code end

    return (
        <>
            <main>

                <Modal open={open} showCloseIcon={false} center>
                    <h4 className='htext'> {data}</h4>
                    <br></br>
                    <button onClick={onCloseModal} type="submit" className="ok">ok</button>
                </Modal>

                {datas ? datas.map((mydata, i) => {
                    return (<>
                        <section className="gt__signup-sec gt__user-sec gt__bg-image gt__bg-image1">

                            <section className="style__ProfileDetails-sc-16fqp05-0 iKHtID">
                                <section className="style__PicContainer-sc-16fqp05-1 fcfhSc">
                                    <div className="style__Wrapper-sc-16fqp05-2 jVWMnN">

                                        <div className="style__FirstName-sc-16fqp05-17 fifige">Hi, {fname} {lname}</div>
                                    </div>
                                </section>
                                <section className="style__AccountDetails-sc-16fqp05-18 kcqWmw">
                                    <div className="style__Header-sc-1ddodym-1 lcuusL">Account Details</div>
                                    <div className="style__PIIDetails-sc-16fqp05-7 hJcxfv">
                                        <div className="style__ModeName-sc-16fqp05-19 eWtCsq">Email Address</div>
                                        <div className="style__ModeDetails-sc-16fqp05-8 kukXfn">

                                            <div >
                                                <span className="style__Mode-sc-16fqp05-9 laaqRQ">{mydata.email}</span>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="style__PIIDetails-sc-16fqp05-7 hJcxfv">
                                        <div className="style__ModeName-sc-16fqp05-19 eWtCsq">Mobile Number</div>
                                        <div className="style__ModeDetails-sc-16fqp05-8 kukXfn">
                                            <div>
                                                <span className="style__Mode-sc-16fqp05-9 laaqRQ">{mydata.mobile_number}</span>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            </section>
                            <div className="gt__signin-wrap gt__user-wrap padding">

                                <div className="gt__signin-inner gt__user-inner">

                                    <div className="gt__form-block">
                                        <h4 className="gt__user-title txt-center"><span>Personal Details</span></h4>
                                        <form method="post" name="userRegistrationForm" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" >

                                            <div className="gt__form-grp gt__uname d-flex d-flex-wrap justify-content-between align-items-center">
                                                <div className="gt__fname-block">

                                                    <label htmlFor="fname">First Name</label>
                                                    <input type="text" name="firstname" {...register('firstname')} defaultValue={fname} />
                                                    <div className="invalid-feedback">{errors.firstname?.message}</div>

                                                </div>

                                                <div className="gt__fname-block">
                                                    <label htmlFor="lname">Last Name</label>
                                                    <input type="text" name="lastname" {...register('lastname')} defaultValue={lname} />
                                                    <div className="invalid-feedback">{errors.lastname?.message}</div>

                                                </div>
                                                <div className="gt__fname-block margin">
                                                    <label htmlFor="mobile">Phone Number</label>
                                                    <input type="text" name="mobile" {...register('mobile')} defaultValue={mydata.mobile_number} />
                                                    <div className="invalid-feedback">{errors.mobile?.message}</div>

                                                </div>
                                                <div className="gt__fname-block margin">
                                                    <label htmlFor="email">Email</label>
                                                    <input type="email" name="email" {...register('email')} defaultValue={mydata.email} />
                                                    <div className="invalid-feedback">{errors.email?.message}</div>

                                                </div>
                                            </div>

                                            <div className="gt__form-block">
                                        <h4 className="gt__user-title txt-center"><span>Address</span></h4>
                                        <br />

                                        <div className="gt__form-grp gt__uname d-flex d-flex-wrap justify-content-between align-items-center">


                                            <div className="gt__fname-block">
                                                <label htmlFor="address">Address</label>
                                                <input type="text" name="address" {...register('address')} defaultValue={mydata.address} />
                                            </div>
                                            <div className="gt__fname-block">

                                                <label htmlFor="suburb">Suburb</label>
                                                <input type="text" name="suburb" {...register('suburb')} defaultValue={mydata.suburb}/>
                                            </div>
                                            <div className="gt__fname-block margin">
                                                <label htmlFor="state">State</label>
                                                <input type="text" name="state" {...register('state')} defaultValue={mydata.state}/>

                                            </div>
                                            <div className="gt__fname-block margin">
                                                <label htmlFor="postcode">Postcode</label>
                                                <input type="text" name="postcode" {...register('postcode')} defaultValue={mydata.postcode}/>

                                            </div>

                                        </div>
                                    </div>
                                            <div className="gt__form-grp">
                                                <div className=" gt__form-btn txt-center margin">
                                                    <button type="submit" className="gt__btn">Save Changes</button>
                                                </div>
                                            </div>
                                        </form></div>
                                </div>
                            </div>




                            <div className="gt__signin-wrap gt__user-wrap padding">

                                <div className="gt__signin-inner gt__user-inner">

                                    <div className="gt__form-block">
                                        <h4 className="gt__user-title txt-center"><span>Change Password</span></h4>
                                        <form method="post" name="userRegistrationForm" onSubmit={handleSubmit(onEdit)} encType="multipart/form-data" >

                                            <div className="gt__form-grp gt__uname d-flex d-flex-wrap justify-content-between align-items-center">
                                                <div className="gt__fname-block">

                                                    <label htmlFor="fname">Old Password</label>
                                                    <input type="text" name="password" {...register('password')} onChange={passwords} />
                                                    {/* <div className="invalid-feedback">{errors.firstname?.message}</div> */}

                                                </div>

                                                <div className="gt__fname-block">
                                                    <label htmlFor="lname">New Password</label>
                                                    <input type="text" name="new_password" {...register('new_password')} onChange={passwords} />
                                                    {/* <div className="invalid-feedback">{errors.lastname?.message}</div> */}

                                                </div>
                                            </div>



                                            <div className="gt__form-grp">
                                                <div className=" gt__form-btn txt-center margin">
                                                    <button type="submit" className="gt__btn">Save Changes</button>
                                                </div>
                                            </div>

                                        </form></div>
                                </div>
                            </div>

                        </section>

                        {/*end SIGN IN sec*/}
                    </>)
                }) : <Modal open={true} showCloseIcon={false} center>
                    <h3><div className="loader1"></div></h3>
                </Modal>}
            </main>
        </>
    )
}