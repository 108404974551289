import React, { useEffect, useState } from "react";
import Slider from 'react-slick'
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Ring from '../images/ring.svg'
import Ling from '../images/line.svg'
import Polygon from '../images/polygon.svg'
import Tickets from '../images/tickets.gif'
import ArrowUp from '../images/arrow-up.svg'
import BannerVideo from '../images/home.mp4'
import Search from '../images/search.svg'

import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import Homeheader from "./Homeheader";
import global from "./global";
import nearevent from "./nearevent"
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./Footer";
import LinesEllipsis from 'react-lines-ellipsis';
import secureLocalStorage from "react-secure-storage";
import Img from 'react-optimized-image';
import WESTERN from '../images/westernaustralia.svg'
import Capital from '../images/australiancapital.svg'
import Wales from '../images/southwales.svg'
import Kangaru from '../images/southaustralia.svg'
import Northern from '../images/northernterritory.svg'
import Queensland from '../images/queensland.svg'
import Tasmania from '../images/tasmania.svg'
import Victoria from '../images/victoria.svg'
import Modal from "react-responsive-modal";
import { Cookies } from "react-cookie";


import User from "../images/user.svg"
import { display, style } from "@mui/system";
import Logo from '../images/The_ticket_company_logo.svg';
import MyTicket from "../images/my-ticket.svg";
import MyProfile from "../images/my-profile.svg";
import LogOut from "../images/logout.svg";


export default function Home() {

    const cookies = new Cookies();

    let citystr = cookies.get('citystr');
    let Skipstr = cookies.get('skipstr');
    const [Reopen, setReopen] = useState(false);

    const [show, setShow] = useState();

    const skip = (skip) => {
        cookies.set("skipstr", skip);
    }
    const [popup, setPoup] = useState(0);


    const [, setNear] = nearevent();

    // Search bar
    const [searchTerm, setsearchTerm] = useState('');

    const onSearchHandler = (e) => {
        const val = e.target.value
        setsearchTerm(e.target.value)


        axios.get(`https://backend.theticketcompany.com.au/api/event?search=${val}&is_future=0&is_past=0&is_pagination=1&skip=0&limit=0&is_desc=0`, config).then((response) => {

            if (response.data.message == "No Event Found") {
                setPoup(null)

            } else {
                setPoup(response.data.response_data);
                setPoup(response.data.response_data);
            }
        }).catch((error) => {
            // console.log(error);
        });

    }




    const [navbar, setNavbar] = useState(false);


    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", changeBackground);
        return () => window.removeEventListener("scroll", changeBackground);
    });




    // LogOut Buton show and hide code start

    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => setShowResults(!showResults)
    const Results = () => (
        <div className="afterlogin">
            <ul>
            <li><Link to="/my-profile/edit" onClick={handleScroll} className="mypro"><img src={MyProfile}></img><p>My Profile</p></Link></li>
                <li><Link to="/my-ticket" className="mypro" onClick={handleScroll}><img src={MyTicket}></img><p>My Ticket</p></Link></li>
                <li><Link to="/" onClickCapture={logout} className="mypro"><img src={LogOut}></img><p>Logout</p></Link></li>

            </ul>
        </div>
    )
    let UserId = cookies.get('id');
    // secureLocalStorage.getItem('accesstoken');
    cookies.get('accesstoken');
    cookies.get('name');
    cookies.get('id');
    const navigates = useNavigate();
    const logout = () => {

        cookies.remove('accesstoken')
        cookies.remove('name')
        cookies.remove('id')
        // window.location = "/";
        navigates();
    };
    // LogOut Buton show and hide code End


    let UserName = cookies.get('name');

    // settings API calling code start
    const config = {
        headers: { 'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh' },
        params: {
            is_pagination: 1,
            skip: 0,
            limit: 0,
            is_desc: 1
        }
    }

    // Page position scroll code start
    const handleScroll = () => {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    // Page position scroll code end

    const [isAccordclose1, setAccordclose1] = useState(true);
    const [showMe1, setShowMe1] = useState(true);

    useEffect(() => {
        if (window.innerWidth > 747) {
            setShowMe1(false)
        }

    });

    const handleToggle1 = () => {
        setAccordclose1(!isAccordclose1);
        setShowMe1(!showMe1);
    };

    // Mobile Responsive code End

    // const sort = sortBy(City, "label");


    // get username code
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');


    const getuserdetails = () => {
        axios.get(`https://backend.theticketcompany.com.au/api/getuser?user_id=${UserId}`, config).then((result) => {

            setFname(result.data.response_data.first_name);
            setLname(result.data.response_data.last_name);

        }).catch((error) => {
            // console.log(error);
        });

    };
    const [showModal, setShowModal] = useState(false)


    const [cdata, setCdata] = useState();
    const addcity = (Cdata) => {
        setCdata(Cdata);
        cookies.set("opencity", Cdata);
    }
    const open = (skips) => {
        cookies.remove('citystr');
        cookies.remove('skipstr');
    }

    // Header code end

    const [eventSearch] = global();
    const [eventnear] = nearevent();


    const [events, setEvents] = useState();


    const [isOpen, setIsOpen] = useState();
    useEffect(() => {

        const body = document.querySelector('body');
        body.style.overflow = isOpen ? 'hidden' : 'auto';


    }, [isOpen])




    // Product details page code start

    const navigate = useNavigate();

    // Product details page code end

    const baseURL = "https://backend.theticketcompany.com.au/image/";
    const bannerURL = "https://backend.theticketcompany.com.au/image/";

    const [data, setData] = useState('');
    useEffect(() => {
        setData(eventSearch);

    }, [eventSearch]);

    const [datas, setDatas] = useState('');

    const getbanner = (city) => {
        if (!citystr && citystr != "" && !Skipstr && Skipstr != "") {
            if (city) {
                cookies.set("citystr", city);
            }
        }

        if (!citystr || citystr == undefined || Skipstr) {
            axios.get('https://backend.theticketcompany.com.au/api/event', config).then((response) => {

                setPoup(response.data.response_data);

            }).catch((error) => {
                // console.log(error);
            });
        }
        else {

            axios.get(`https://backend.theticketcompany.com.au/api/event?search=${citystr}`, config).then((response) => {

                if (response.data.message == "No Event Found") {

                    setPoup("")
                }
                else {
                    setPoup(response.data.response_data);
                }

            }).catch((error) => {
                // console.log(error);
            });
        }

    };


    const getbanners = () => {
        axios.get('https://backend.theticketcompany.com.au/api/event', config).then((response) => {

            setDatas(response.data.response_data);

        }).catch((error) => {
            // console.log(error);
        });
    };

    var settings = {
        dots: false,
        infinite: true,
        speed: 350,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        accessibility: false,


    };

    useEffect(() => {
        getbanner();

    }, [show, isOpen, data]);


    let opens = cookies.get('datas');

    useEffect(() => {
        getuserdetails();
        getbanners();
    }, []);

    return (
        <>
            {/* <Homeheader /> */}
            <header className='gt__fixed-heder' onClick={() => showModal ? display = "block" : " none"}>
                <div className="gt__header-wrap d-flex d-flex-wrap justify-content-between align-items-center">
                    <div className="gt__header-left">
                        <NavLink to="/" onClick={handleScroll} className="gt__header-logo">  <img src={Logo} alt="The_ticket_company" /></NavLink>
                        <div id="mobilemenu" onClick={handleToggle1} className={`${isAccordclose1 ? "submenu" : "closebtn"}`}></div>
                    </div>

                    <div className="gt__header-right d-flex d-flex-wrap justify-content-between align-items-center">

                        <div className="gt__header-menu submenu" style={{ display: !showMe1 ? "block" : "none" }}>
                            <ul className="d-flex d-flex-lg-wrap align-items-center">
                                <li> <Link to="/events">Events</Link></li>
                                <li> <Link to="/combo-events">Combo Events</Link></li>
                                {/* <li><NavLink to="#">Offers</NavLink></li> */}
                            </ul>
                        </div>


                        <div className="gt__header-socialsec d-flex d-flex-wrap justify-content-between align-items-center">
                            <div className="gt__search-block gt__img-middle search_bar submenu" style={{ display: !showMe1 ? "block" : "none" }}>
                                <input
                                    type="search"
                                    className="search"
                                    placeholder="Search Event / Artist "
                                    value={searchTerm}
                                    onChange={onSearchHandler}

                                />


                                <img src={Search} alt="search" width={24} height={24} />

                            </div>
                            <div className="gt__location gt__img-middle cityfilter">
                                <span className="mypro" onClick={() => { setIsOpen(true); setShow(!show); open("skips"); }}>{citystr || "Select State"}  </span>


                            </div>



                            <div className="gt__user-details gt__img-middlesubmenu" style={{ display: !showMe1 ? "block" : "none" }} onClick={onClick}>
                                {fname &&
                                    <NavLink to="#">
                                        <img src={User} alt="search" width={24} height={24} />
                                        <span className="mypro">{fname}</span>

                                    </NavLink>

                                    || !fname &&

                                    <NavLink to="/login" onClick={handleScroll}>
                                        <img src={User} alt="search" width={24} height={24} />
                                        <span className="mypro">{'Sign in'} </span>
                                    </NavLink>
                                }
                                {fname && <div>{showResults ? <Results /> : null}</div>}
                            </div>

                        </div>
                    </div>



                </div>


            </header>


         {<main>


                <Slider {...settings}>
                    {datas ? datas.map(datas => {
                        return (

                            <div key={datas.id}> <Link onClick={handleScroll} to={`/event-detail/${datas.event_name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()}/${datas.id}`} >
                                <div>
                                    <div className="slide slide1" >
                                        <img src={bannerURL + datas.event_banner} loading="lazy" />
                                        </div>
                                    </div>
                            </Link>
                            </div>


                        )
                    }) : <h3><div className="loader"></div></h3>}
                    {/* <div className="videoblog">
                        <span className="videospan">Let Us Help You To Find Your Next Event</span> */}
                    {/* {citystr && <button className="gt__btn " id="allcity" onClick={() => setShow1(show1)}>Select Your State</button>} */}
                    {/* <video src={BannerVideo} type="video/mp4" width="100%" muted autoPlay={"autoplay"} preload="auto" loop /> */}

                    {/* </div> */}
                </Slider>



                {<section className="gt__nearevent-sec gt__bg-image gt__bg-image1">
                    <div className="gt__space-around">
                        <img className="gt__ring-img round-animation" src={Ring} alt="ring" width={60} height={61} loading="lazy" />
                        <img className="gt__line-img up-down" src={Ling} alt="line" width={85} height={86} loading="lazy" />
                        <img className="gt__ring-bootom-img round-animation" src={Ring} alt="ring" width={60} height={61} loading="lazy" />
                        <img className="gt__polygon-img up-down" src={Polygon} alt="polygon" width={45} height={47} loading="lazy" />
                        <div className="gt__title-wrap d-flex d-flex-wrap justify-content-between align-items-center" data-aos="fade-up">

                            <h2>UPCOMING EVENTS</h2>
                            {/* <Link to="/events">See All</Link> */}
                        </div>
                       {popup &&  <div className="gt__events-wrap d-flex d-flex-wrap ">
                            {popup ? popup.map((data, s) => {

                                return (
                                    <div key={s} className="event_common_block gt__events-block" data-aos="fade-up">
                                        {/* <a href="#"> */}
                                        <div className="gt__events-topsec">
                                            <div className="gt__img-block">
                                                <Link onClick={handleScroll} to={`/event-detail/${data.event_name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()}/${data.id}`} >
                                                    <img src={baseURL + data.event_image}
                                                        alt="user" width={251} height={377} />
                                                </Link>
                                            </div>
                                            <div className="gt__events-date">
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="gt__events-content">
                                            <h3><b>
                                                <LinesEllipsis
                                                    text={data.event_name}
                                                    maxLine='1'
                                                    ellipsis='...'
                                                    trimRight
                                                // basedOn='letters'
                                                />
                                            </b></h3>
                                            <p><LinesEllipsis
                                                    text={data.categories}
                                                    maxLine='1'
                                                    ellipsis='...'
                                                    trimRight
                                                // basedOn='letters'
                                                /></p>
                                            <p className="cdateadd calender_date">{data.event_date}<br />{data.event_time}</p>
                                            <p className="cdateadd address">
                                                <LinesEllipsis
                                                    text={data.place_name}
                                                    maxLine='1'
                                                    ellipsis='...'
                                                    trimRight
                                                // basedOn='letters'
                                                />

                                            </p>
                                            <Link onClick={handleScroll} to={`/event-detail/${data.event_name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()}/${data.id}`}> <button className="gt__btn">Buy Tickets</button> </Link>
                                        </div>
                                        {/* </a> */}
                                    </div> || <div className="loader"></div>

                                )

                            })

                                : <h2>Not Found Any Event in your State</h2>}

                        </div> || <h2>Not Found Any Event in your State</h2>}

                    </div>
                </section> || <h3>Event Not Found</h3>}

                <div className="gt-top-button">
                    <a id="toTop" onClick={handleScroll}><img src={ArrowUp} alt="gotop" width={24} height={24} loading="lazy" /></a>
                </div>

            </main> }


            {/* <Modal > */}


            {!cookies.citystr && !citystr && !Skipstr && <div className={show ? "containers2" : "containers1"}>

                <div className={show ? "" : "section"}>
                    {/* <div className="search">
                       <input id="serch" type="search" placeholder="search" />
                   </div> */}
                    <div className="citys">
                        <h2>Select Your State</h2>
                        <ul>
                            <li onClick={() => { getbanner('Victoria'); setIsOpen(false); }} >
                                <img src={Victoria} alt="Victoria" onClick={() => { setShow(!show); }} />
                                <h2 onClick={() => { setShow(!show); }}>Victoria</h2>
                            </li>
                            <li onClick={() => { getbanner('New South Wales');; setIsOpen(false); }}>
                                <img src={Wales} alt="South Wales" onClick={() => { setShow(!show); }} />
                                <h2 onClick={() => { setShow(!show); }}>New South Wales</h2>
                            </li>
                            <li onClick={() => { getbanner('Queensland'); setIsOpen(false); }} >
                                <img src={Queensland} alt="Queensland" onClick={() => { setShow(!show); }} />
                                <h2 onClick={() => { setShow(!show); }}>Queensland </h2>
                            </li>


                            <li onClick={() => { getbanner('South Australia'); setIsOpen(false); }}>
                                <img src={Kangaru} alt="South Australia" onClick={() => { setShow(!show); }} />

                                <h2 onClick={() => { setShow(!show); }}>South Australia</h2>
                            </li>

                        </ul>
                        <ul className="citysize">
                            <li id="WAustralia" onClick={() => { getbanner('Western Australia'); setIsOpen(false); }}>
                                <img src={WESTERN} alt="Western Australia" onClick={() => { setShow(!show); }} />
                                <h2 onClick={() => { setShow(!show); }}>Western Australia</h2>
                            </li>
                            <li onClick={() => { getbanner('Australian Capital'); setIsOpen(false); }}>
                                <img src={Capital} alt="Australian Capital" onClick={() => { setShow(!show); }} />
                                <h2 onClick={() => { setShow(!show); }}>Australian Capital</h2>

                            </li>

                            <li onClick={() => { getbanner('Northern Territory'); setIsOpen(false); }}>
                                <img src={Northern} alt="Northern Territory" onClick={() => { setShow(!show); }} />
                                <h2 onClick={() => { setShow(!show); }} >Northern Territory</h2>
                            </li>

                            <li onClick={() => { getbanner('Tasmania'); setIsOpen(false); }}>
                                <img src={Tasmania} alt="Tasmania" onClick={() => { setShow(!show); }} />
                                <h2 onClick={() => { setShow(!show); }}>Tasmania</h2>
                            </li>


                        </ul>
                    </div>

                    <input type="button" id="btn" value="skip" onClick={() => { setShow(!show); skip('skip'); setIsOpen(false); }} />
                </div>
            </div>
            }


            {/* </Modal> */}
            <Footer />
        </>
    )
}