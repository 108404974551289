import React, { useEffect, useState } from "react";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Home() {

    return (
        <>
            <main >
                <section className="termsservice  gt__bg-image categorysec categorysec2 categorysec3" >
                    <div className="container">
                        <div className="ctextinfo">
                            <h1>Terms & Conditions</h1>
                            <p>
                                The following terms and conditions apply to any ticket issued by TheTicketCompany and may result in revocation of the ticket by the issuer, event organizer, and/or venue proprietor for any breach of these conditions:</p>
                            <ul className="cpoint">
                                <li>
                                    No refunds or exchanges will be given for any ticket, booking fee, delivery fee, or service fee, except where required by law.
                                </li>

                                <li>
                                    The event organizer reserves the right to withdraw, add, substitute or reschedule any artist, performer or team.
                                </li>
                                <li>
                                    The ticket prices, show timings, seating layouts, door times or venue capacity may be changed by the event organizer without prior notice.
                                </li>
                                <li>
                                    Admission to the venue is subject to the terms and conditions laid by the event organizer and the venue, which may be obtained on request from the venue or organizer.
                                </li>
                                <li>
                                    Late arrivals to the venue may experience a seating delay until there is a break in the event.
                                </li>
                                <li>
                                    As a ticket holder, you grant permission to be searched and have your clothing, bags, and other possessions inspected by employees, officials, or agents of the venue proprietor or event organizer both prior to and after entry to the event venue.
                                </li>
                                <li>
                                    Admission to the venue is subject to event-specific terms and conditions laid by the organizer and the venue, which may include bag searches, confiscation of restricted items, and restrictions on the use of cameras, flash photography, audio, and video recorders of any type, conducted by those hired by the event organizer, event officials, or venue proprietor.
                                </li>

                                <li>
                                    The Ticket Holder shall be required to leave the event venue if he or she engages in any kind of misconduct, including fighting, breaking the law and order applicable to the event, or unreasonably hampering the conduct of the event.
                                </li>
                                <li>
                                    The person who purchased the tickets has the right to pick them up. In case of a credit card purchase, the buyer must carry the credit card as ID while picking up tickets.
                                </li>
                                <li>
                                    Any ticket holder found engaged in any form of misconduct, breaching the terms and conditions set by the event organizers or breaking the law, will be told to leave the venue.</li>
                                <li>
                                    Use of cookies is allowed while using our website/App.
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}