import axios from "axios";

const CryptoJS = require("crypto-js");
const { v4: uuidv4 } = require("uuid");
// const request = require("request");

const apiKey = "SYmCyLbtAmbM7WLU0bLAQvWIGrrmytK3";
const secret = "hxdToS514XScxUCqMiDeUAVMuar0sNOMwqIRbAecfGO";
const content = "Insert your request content here, empty for GETs";
// const Url = "https://prod.api.firstdata.com/gateway/v2"
const Url = "https://prod.emea.api.fiservapps.com/exp/v1/checkouts";

export default function fiservEncode() {
    const body = '{"storeId":"3650650452","transactionType":"SALE","transactionAmount":{"total":5,"currency":"EUR","components":{"subtotal":4,"vatAmount":1}},"order":{"basket":{"lineItems":[{"itemIdentifier":"100","name":"Orange juice","price":2,"quantity":2,"total":5}]}},"checkoutSettings":{"locale":"en_GB","webHooksUrl":"https://webhook.site/47ae26ee-ac72-4ce1-b872-e1dec58c9817","redirectBackUrls":{"successUrl":"https://uat.theticketcompany.com.au/success/","failureUrl":"https://www.failure.com/"}}}';

    const ClientRequestId = uuidv4();
    const time = new Date().getTime();
    //const requestBody = JSON.stringify(body);
    // if (method === 'GET') {
    //     requestBody = '';
    // }

    const rawSignature = apiKey + ClientRequestId + time + body;
    var computedHash = CryptoJS.algo.HMAC.create(
        CryptoJS.algo.SHA256,
        secret.toString()
    );
    computedHash.update(rawSignature);
    computedHash = computedHash.finalize();
    const messageSignature = CryptoJS.enc.Base64.stringify(computedHash);


    const options = {
        method: 'POST',
        url: Url,
        headers: {
            // accept: 'application/json',
            'content-type': 'application/json',
                'Api-Key': apiKey,
                'Client-Request-Id': ClientRequestId,
                'Message-Signature': messageSignature,
                'Timestamp': time,
            },
            data: body
        };

        axios
            .request(options)
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                console.error(error);
            });



    return (

        <section className="termsservice  gt__bg-image categorysec categorysec2 categorysec3" >
            <div className="container" >
                <div className="ctextinfo">
                    <h1>Career</h1>
                    <h3>
                        Message-Signature
                    </h3>
                    <span>
                        {messageSignature}
                    </span>
                    <h3>
                        Timestamp

                    </h3>
                    <span>
                        {time}
                    </span>
                    <h3>
                        Client-Request-Id

                    </h3>
                    <span>
                        {ClientRequestId}
                    </span>


                </div>
            </div>
        </section>

    )
}



// export default function Career() {


// }