import React, { createRef, useEffect, useRef, useState } from 'react'
import { Cookies } from 'react-cookie'
import axios from 'axios';
import ArrowUp from '../images/arrow-up.svg'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import secureLocalStorage from "react-secure-storage";

export default function Myticket() {

    // Page position scroll code start
    const handleScroll = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    const [loader, setLoader] = useState(false);
    const onLoadModal = () => setLoad(true);

    const [slice, setslice] = useState(1);
    const [load, setLoad] = useState("");
    const baseURL = "https://backend.theticketcompany.com.au/image/eventBanner/";
    const cookies = new Cookies();
    let UserId = cookies.get('id');
    const [ticketinfo, setData] = useState();
    const headers = {
        'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',
    }

    const getticketdetails = () => {
        axios.get(`https://backend.theticketcompany.com.au/api/booking-history?user_id=${UserId}`,{
            headers: headers
        }).then((result) => {

            console.log(result.data.response_data.event_infos);
            setData(result.data.response_data.event_infos);

        }).catch((error) => {
            // console.log(error);
        });

    };

    useEffect(() => {
        getticketdetails();
    }, [UserId])

    const handleload = (id) => {
        const getid = id;



        setslice(!slice);
        if (slice !== false) {
            setLoad(getid)
        }
        else {
            setLoad("")
        }

        // console.log(slice);

    }
    // console.log(load)


    return (
        <>
            <main className="myticket1">

                <div className="gt-top-button">
                    <a id="toTop" onClick={handleScroll}><img src={ArrowUp} alt="gotop" width={24} height={24} /></a>
                </div>
                {ticketinfo ? ticketinfo.map((Myticket, i) => {



                    return (
                        <>
                            <div className='my_ticket'>
                                {Myticket.data.length != 0 &&
                                    <p>No of ticket - {Myticket.data.length}</p>
                                }
                                {Myticket.data.slice(0, slice || Myticket.data.length).map((result, index) => {
                                    let newdate = new Date(result.event_date).toLocaleDateString('en-GB')
                                    return (<>
                                        <div className="profile-tabs" data-id="allBookings" data-role="memberTicketData"  >

                                            <section className="tickets">
                                                <ul className="confirmation-list" id="ALLBOOKINGS">
                                                    {/* <li>Getting your orders...</li> */}
                                                    <li className="confirmation-card" data-showstarttime="09:30am" data-role="card" data-booking-id="WKJM7GH" data-trans-id="3336478707" data-trans-status="P">
                                                        <aside className="details">

                                                            <div className="bcode" id="barcode_3336478707">

                                                                <div className="__qrcode {{ className }}">
                                                                    <img src={result.qrcode_image} alt="" />
                                                                </div>

                                                                <div className="__booking-id" id="bidText_3336478707">Booking Number
                                                                    <p className="_bkn_id bookingno"><strong>{result.booking_no}</strong></p>

                                                                </div>
                                                            </div>

                                                            <div className="info">
                                                                <div className="__img"><img src={baseURL + result.event_image}
                                                                    alt="user" /></div>
                                                                <div className="text">
                                                                    <div className="inner-text">
                                                                        <div>
                                                                            <h1 >{result.event_name}</h1>
                                                                            <span className="ticketMode"></span>
                                                                        </div>
                                                                        <p><span className="venue-pin"></span>
                                                                            {result.place_name} | {result.place_address}  <br /> {result.event_time} | {newdate}</p><span id="Qyt-3336478707" className="Qyt">Quantity: 1</span>

                                                                        <div className="__seats">
                                                                            <span className="icon-seat">

                                                                            </span>
                                                                           { result.place_type == "Party Plot" && <strong id="seatno-3336478707">{result.title}</strong> || <strong id="seatno-3336478707">{result.title} - {result.seat_number}</strong>}
                                                                            <span>WKJM7GH</span>
                                                                        </div>
                                                                    </div>
                                                                    <div id="booking-done_3336478707" className="booking-summary">
                                                                        <a id="btnBookingSummary" className="__btnBooking" >
                                                                            <span className="icon">

                                                                            </span>
                                                                            <span className="__desc" id="splitDesc_3336478707"></span>
                                                                            <span id="no-friends_3336478707"></span>
                                                                        </a>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </aside>
                                                        <div className="booking-details">
                                                            <p>BOOKING DATE &amp; TIME
                                                                <br /><span>{Myticket.date}</span></p>

                                                            {Myticket.data.length <= 1 && Myticket.data.length -1 == index||
                                                                <>
                                                                    <button id={Myticket.id} onClick={() => handleload(Myticket.id)} className="gt__btn" >{load == Myticket.id && "Show less" || "Show more"}</button>

                                                                </>
                                                            }
                                                        </div>
                                                    </li>
                                                </ul>

                                            </section>
                                        </div>

                                    </>)
                                })}
                            </div>

                        </>)



                }) :
                    <h3 className="loader"></h3>
                }

            </main>
        </>
    );
}