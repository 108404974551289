import React, { useEffect, useState } from "react";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Home() {


    return (
        <>
            <main >
                <section className="termsservice  gt__bg-image categorysec categorysec2 categorysec3">
                    <div className="container">
                        <div className="ctextinfo">
                            <h1>Payment & Refund Policy</h1>
                            <br />
                            <p>
                                Our Payment & Refund Policy states that all ticket purchases are generally non-refundable and non-transferable. We advise you to carefully review your event and ticket selection before making a purchase. Unless there is a cancellation, rescheduling, or relocation of an event by the organiser or circumstances required by Live Performance Australia code of practice for Event Ticketing, NO REFUND OR EXCHANGE ON ANY TICKET will be given, including the service fee.
                            </p>
                            <p>
                                However, if you are unable to attend the event due to genuine reasons, please inform us, and we will forward your request to the event organisers. The decision to grant a partial refund or transfer to a forthcoming event is at the discretion of the event organiser. Please note that The Ticket Company is not responsible for any such decision.
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}