import checkdetail from "./checkdetail"
import { useEffect, useState } from "react";
import { Cookies } from 'react-cookie'
import Slider from 'react-slick'
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import backButton from 'browser-back-button';
import secureLocalStorage from "react-secure-storage";
import ExpiredStorage from "expired-storage";
import { Modal } from 'react-responsive-modal';


export default function Faild() {

    // sesssion time out
    const navigate = useNavigate();

    const [refreshData, setRefreshData] = useState(false);
    const expiredStorage = new ExpiredStorage();
    const item = expiredStorage.getItem("test");


    useEffect(() => {

        const item = expiredStorage.getItem("test");

    }, [refreshData])

    const [session, setsession] = useState(true);
    const closesession = () => {

        setsession(false);
        navigate("/")

    }



    return (<>
        <main>
            <section className="thanyoupage">
                <div className="d-flex justify-content-center">
                    <div className="w-100 text-center thanktexts">
                        <div className="micon"></div>
                        <h3>Your Transaction is failed or declined <br />please try again!</h3>
                        {/* <p>You will get a confirmation message in email shortly</p> */}
                        <Link to="/"><a className="cbtn">Back To Home Page</a></Link>
                    </div>
                </div>
            </section>
        </main>

    </>)


}