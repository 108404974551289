import React, { useEffect, useState } from "react";
import Slider from 'react-slick'
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useParams } from "react-router-dom";
import ArrowUp from "../images/arrow-up.svg"
import Share from './share'
import { Cookies } from 'react-cookie'
import ReadMoreText from 'read-more-less-react';
import 'read-more-less-react/dist/index.css'
import secureLocalStorage from "react-secure-storage";
import ExpiredStorage from "expired-storage";




export default function Events() {


    const [refreshData, setRefreshData] = useState(false);

    // Page position scroll code start
    const handleScroll = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    // Page position scroll code end

    const [int, setInt] = useState('Interested ?');
    // let UserId = secureLocalStorage.getItem('id');
    const cookies = new Cookies();
    let UserId = cookies.get('id');
    // home to details page start
    const { id } = useParams();


    // home to details page end
    const headers = {
        'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',
    }

    // Image url path

    const baseURL = "https://backend.theticketcompany.com.au/image/";
    const baseURLs = "https://backend.theticketcompany.com.au/image/eventVideo";
    const sponserimgURL = "https://backend.theticketcompany.com.au/image/sponser/"
    const OrganizerimgURL = "https://backend.theticketcompany.com.au/image/organizerLogo/"



    const [data, setData] = useState();
    const [sponser, setsponser] = useState();
    const [datas, setDatas] = useState();
    const [ComboDatas, setComboDatas] = useState();
    const [Dates, SetDates] = useState([]);
    const [Times, SetTimes] = useState({});
    const [Id, SetId] = useState([]);

    const getAllData = () => {
        axios.get(`https://backend.theticketcompany.com.au/api/comboeventsdetails?comboEventId=${id}&userId=${UserId}`, {
            headers: headers
        }).then((response) => {

            setData(response.data.response_data);
            setComboDatas(response.data.response_data);
            localStorage.setItem("combo_event_id", response.data.response_data.id);
            localStorage.setItem("combo_event_discount", response.data.response_data.combo_event_discount);
            SetDates(response.data.response_data.eventDetails);

            localStorage.setItem("combo_event_name", response.data.response_data.event_name);
            localStorage.setItem("event_type", response.data.response_data.event_type);
            localStorage.setItem("combo_place_name", response.data.response_data.place_name);
            localStorage.setItem("combo_event_date", response.data.response_data.event_date);




            if (Array.isArray(response.data.response_data.eventDetails)) {
                setInterval(() => {
                    const newdate = {};
                    response.data.response_data.eventDetails.forEach(element => {
                        const dates = times(element.event_date);
                        newdate[element.id] = dates;

                        const eventid = times(element.id);
                    });
                    SetTimes({ ...Times, ...newdate });



                }, 1000);


            }
            if (response.data.response_data.likes == 'true') {

                setInt('Interested')
            }
            else {
                setInt('Interested ?')
            }


            localStorage.setItem("event_date", response.data.response_data.eventDetails[0].event_date);
            setRefreshData(false)


        }).catch((error) => {
            // console.log(error);
        });
    };
    const getId = () => {

        axios.get(`https://backend.theticketcompany.com.au/api/comboeventsdetails?comboEventId=${id}&userId=${UserId}`, {
            headers: headers
        }).then((response) => {

            SetId(response.data.response_data.eventDetails);
            //  console.log('Ids', response.data.response_data)
            if (response.data.response_data.likes == 'true') {

                setInt('Interested')
            }
            else {
                setInt('Interested ?')
            }
            // console.log(Id);
            var lengthstr = Id.length;
            let ids = [];
            let place_id = [];
            let event_name = [];
            let place_name = [];
            let events_date = [];
            let combo_event_discount = [];
            // console.log(lengthstr);
            for (let indexs = 0; indexs < lengthstr; indexs++) {
                // SetCount()
                // console.log(Id[indexs]);
                ids.push(Id[indexs].id);
                place_id.push(Id[indexs].place_id);
                event_name.push(Id[indexs].event_name);
                place_name.push(Id[indexs].place_name);
                events_date.push(Id[indexs].event_date);
                combo_event_discount.push(Id[indexs].combo_event_discount);
                // ids +=Id[index].id+",";

            }
            localStorage.setItem("place_id", place_id);
            localStorage.setItem("event_name", event_name);
            localStorage.setItem("place_name", place_name);
            localStorage.setItem("events_id", ids);
            localStorage.setItem("events_date", events_date);






        }).catch((error) => {
            // console.log(error);
        });
    };

    const getsponser = () => {
        axios.get(`https://backend.theticketcompany.com.au/api/eventsponser?EventId=${id}`, { headers: headers }).then((response) => {

            setsponser(response.data.response_data);


        }).catch((error) => {
            // console.log(error);
        });
    };

    const getbanner = () => {
        axios.get('https://backend.theticketcompany.com.au/api/comboeventsdetails', {
            headers: headers
        }).then((response) => {

            setDatas(response.data.response_data);

        }).catch((error) => {
            // console.log(error);
        });
    };


    useEffect(() => {
        getAllData();

    }, [refreshData])

    useEffect(() => {
        getAllData();
        getsponser();
        getbanner();
        getId();

    }, [id, Id]);



    const times = (e) => {
        const date2 = new Date(e);
        // console.log('date2', date2)
        const difference = Date.parse(date2) - Date.parse(new Date());

        if (difference > 0) {
            let days = Math.floor(difference / (1000 * 60 * 60 * 24));
            let hours = Math.floor((difference / 1000 / 60 / 60) % 24);
            let minutes = Math.floor((difference / 1000 / 60) % 60);
            let seconds = Math.floor((difference / 1000) % 60);
            const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
            //  days = `${days}`;
            if (numbersToAddZeroTo.includes(hours)) {
                hours = `0${hours}`;
            } else if (numbersToAddZeroTo.includes(minutes)) {
                minutes = `0${minutes}`;
            } else if (numbersToAddZeroTo.includes(seconds)) {
                seconds = `0${seconds}`;
            }
            return { days, hours, minutes, seconds }
        }


    }
    // Count Down Code end

    // Like Event Code Start
    const [likedata, setLike] = useState()

    function likeEvent(e) {
        const getId = e.target.id;
        const likedata = data.likes
        // console.log(likedata);


        if (!getId) {
            // console.log("Please Login First");
            window.location = "/login";
        }
        else {
            setRefreshData(true)
            if (likedata == 'false') {
                const body = new FormData();

                body.append("user_id", getId);
                body.append("event_id", id);


                const headers = {
                    'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


                }
                axios.post('https://backend.theticketcompany.com.au/api/likes', body, {
                    headers: headers
                })
                    .then((res) => {
                        if (getId) {
                            setLike(res.data.response_data);

                        }
                        else {
                            window.location = "/login";
                        }
                    }).catch((error) => {
                        // console.log(error)
                    });



            } else {

                getAllData();
                const body = new FormData();

                body.append("user_id", getId);
                body.append("event_id", id);


                const headers = {
                    'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


                }
                axios.post('https://backend.theticketcompany.com.au/api/dislikes', body, {
                    headers: headers
                })
                    .then((res) => {
                        if (getId) {
                            setLike(res.data.response_data);

                        }
                        else {
                            window.location = "/login";
                        }
                    }).catch((error) => {
                        // console.log(error)
                    });



            }

        }

    }
    var sponserSettings = {
        dots: true,
        infinite: true,
        speed: 350,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 350,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,


    };



    return (
        <>

            {data && <main>
                <Slider {...settings}>

                    <div className="slide slide-height" >

                        <img className="slider-image" src={baseURL + data.event_banner} />
                    </div>
                    {/* {data.event_video == "eventVideo/" ||
                        <div className="slide slide-height" >
                            <video src={baseURL + data.event_video} type="video/mp4" width="200"  muted autoPlay={"autoplay"}  preLoad="auto" loop />
                        </div>
                    } */}
                </Slider>

                {/* hero section end*/}


                <section className="eventinfo">
                    <div className="container">
                        <div key={data.id}>
                            <div className="eventtitle">
                                <h2 className="ctitle" style={{ "width": "80%", "lineHeight": "30px" }}>{data.event_name}</h2>
                                <p>{data.categories} | {data.event_hours} </p>
                                {data.place_name && data.place_name == "Plenary  Melbourne Convention and Exhibition Centre - P" && <NavLink onClick={handleScroll} to="/plenary" className="cbtn cbtns" >Buy Tickets</NavLink> ||
                                    data.place_type == "Party Plot" &&
                                    <NavLink onClick={handleScroll} to="" className="cbtn cbtns">Buy Tickets</NavLink> ||
                                    <NavLink onClick={handleScroll} to="/combo-seat-booking" className="cbtn cbtns" >Buy Tickets</NavLink>
                                }

                            </div>

                            {ComboDatas ? ComboDatas.eventDetails.map(ComboDatas => {
                                let days = "00";
                                let hours = "00";
                                let minutes = "00";
                                let seconds = "00";

                                if (Times.hasOwnProperty(ComboDatas.id)) {
                                    if (typeof Times[ComboDatas.id] === "object") {
                                        if (Times[ComboDatas.id].days) {


                                            if (Times[ComboDatas.id].hasOwnProperty("days")) {
                                                days = Times[ComboDatas.id].days;
                                            }
                                        }
                                        if (Times[ComboDatas.id].hours) {


                                            if (Times[ComboDatas.id].hasOwnProperty("hours")) {
                                                hours = Times[ComboDatas.id].hours;
                                            }
                                        }
                                        if (Times[ComboDatas.id].minutes) {


                                            if (Times[ComboDatas.id].hasOwnProperty("minutes")) {
                                                minutes = Times[ComboDatas.id].minutes;
                                            }
                                        }
                                        if (Times[ComboDatas.id].seconds) {


                                            if (Times[ComboDatas.id].hasOwnProperty("seconds")) {
                                                seconds = Times[ComboDatas.id].seconds;
                                            }
                                        }
                                    }

                                }
                                return (
                                    <>
                                        <h1>
                                            {ComboDatas.event_name}
                                        </h1>
                                        <ul>
                                            <li>
                                                <div className="icon icon1" />
                                                <div className="desc">
                                                    <h3>{ComboDatas.event_date} At {ComboDatas.event_time}</h3>
                                                    {/* <p>{ComboDatas.event_booking_date} To {ComboDatas.event_booking_end_date} <br />
                                                    </p> */}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon icon2" />
                                                <div className="desc">
                                                    <h3>Location/Venue</h3>
                                                    <p>{ComboDatas.place_name} </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon icon3" />
                                                <div className="desc">
                                                    <h3>Tickets</h3>
                                                    <p className="price">${ComboDatas.lowestPrice} - ${ComboDatas.highestPrice}</p>
                                                </div>
                                            </li>


                                            <li>
                                                <div>
                                                    <div className='countdown-wrapper'>

                                                        <div className='time-section'>
                                                            <div className='time'>{days || '00'}</div>
                                                            <small className="time-text">Days</small>
                                                        </div>
                                                        <div className='time-section'>
                                                            <div className='time'>:</div>
                                                        </div>
                                                        <div className='time-section'>
                                                            <div className='time'>{hours || '00'}</div>
                                                            <small className="time-text">Hours</small>
                                                        </div>
                                                        <div className='time-section'>
                                                            <div className='time'>:</div>
                                                        </div>
                                                        <div className='time-section'>
                                                            <div className='time'>{minutes || '00'}</div>
                                                            <small className="time-text">Minutes</small>
                                                        </div>
                                                        <div className='time-section'>
                                                            <div className='time'>:</div>
                                                        </div>
                                                        <div className='time-section'>
                                                            <div className='time'>{seconds || '00'}</div>
                                                            <small className="time-text">Seconds</small>
                                                        </div>
                                                    </div>

                                                </div>
                                            </li>

                                        </ul>
                                    </>

                                )
                            }) : <h3><div className="loader"></div></h3>}
                        </div>


                    </div>
                </section>
                <section className="eventabout">
                    <div className="container">
                        <div className="eventimgbox">
                            <img className="eventimgbox1" src={data.event_artist_image || "Image not found"} alt="" />
                        </div>

                        <div className="eventtext ctectinfo">
                            <span style={{ fontWeight: "bold" }}>ABOUT :</span>
                            <div className="extra-content cpoint" dangerouslySetInnerHTML={{ __html: data.event_about }} />

                            <br />
                            <div>
                                <span style={{ fontWeight: "bold" }}>Terms & condition :</span>
                                <div className="cpoint" dangerouslySetInnerHTML={{ __html: data.event_terms }} />
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>Event Disclaimer :</span>

                                <div className="cpoint" dangerouslySetInnerHTML={{ __html: data.event_disclaimer }} />
                            </div>
                            <div className="notetext">
                                <span style={{ fontWeight: "bold" }}>Note :</span>
                                {/* <ul className="cpoint">
                                    <li>{data.event_note}</li>
                                </ul> */}
                                <div className="cpoint" dangerouslySetInnerHTML={{ __html: data.event_note }} />
                            </div>
                            <br />

                            <div className="interested">
                                <h5>Click on Interested to stay updated about this event.</h5>

                                <div className="like" >{data.likeCounts}</div>

                                <p>People have shown interest recently</p>
                                {int === "Interested" && <a className="cbtn cbtn2" id={UserId} onClick={(e) => (likeEvent(e))} >{int}</a>

                                    || <a className="cbtn cbtn1" id={UserId} onClick={(e) => (likeEvent(e))} >{int}</a>
                                }
                            </div>
                            <br /><br />
                            <div className="shareevent d-flex">
                                <h5>Share This Event :- </h5>
                                <div className="gt__social-img gt_social_icons d-flex d-flex-wrap align-items-center">
                                    <div className="d-flex align-items-center">
                                        <Share />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="organizers">
                            <h2>
                                Organised By -
                            </h2>
                            <Slider {...sponserSettings}>
                                {ComboDatas ? ComboDatas.eventDetails.map((ComboDatas, index) => {
                                    return (
                                        <div className="eventimgbox">

                                            <h3 className="organizername">
                                                {ComboDatas.organizer_name}
                                            </h3>

                                            <img style={{ width: "60%" }} className="eventimgbox1" src={OrganizerimgURL + ComboDatas.organizer_logo} alt="" />
                                        </div>)
                                }) : ""}
                            </ Slider>
                        </div>
                    </div>
                </section>
                {/* <section className="sponsorsection">
                    <Slider {...sponserSettings}>
                        {sponser ? sponser.map((sponser, index) => {
                            return (<div className="container" key={index}>

                                <div className="sponsorbox">
                                    <div className="imgbox">
                                        <img src={sponserimgURL + sponser.sponser_logo} alt="" />
                                    </div>
                                    <div className="desc">
                                        <h3>{sponser.sponser_name}</h3>
                                        <p>{sponser.sponser_information}</p>
                                        <p>We are proud to say that, This Event is sponsored by Domino's Pizza</p>
                                    </div>

                                </div>

                            </div>)
                        }) : <> </>}
                    </Slider>
                </section> */}

                {/* <section className="sponsorsection">
                    <Slider {...sponserSettings}>
                        {ComboDatas ? ComboDatas.eventDetails.map((ComboDatas,index) => {
                            return (<div className="container" key={index}>

                                <div className="sponsorbox">
                                    <div className="imgbox">
                                        <img src={OrganizerimgURL + ComboDatas.organizer_logo} alt="" />
                                    </div>
                                    <div className="desc">
                                        <h3>{ComboDatas.organizer_name}</h3>
                                        <p>{sponser.sponser_information}</p>
                                        <p>We are proud to say that, This Event is sponsored by Domino's Pizza</p>
                                    </div>

                                </div>

                            </div>)
                        }) : <> </>}
                    </Slider>

                </section> */}
                {/* Top button start*/}
                <div className="gt-top-button">
                    <a id="toTop" onClick={handleScroll}><img src={ArrowUp} alt="gotop" width={24} height={24} /></a>
                </div>
                {/* Top button end*/}


            </main> || <h3><div className="loader"></div></h3>}
        </>
    )
}